<template>
	<div class="transitionPage">
		<div id="VirtualMonument" class="pageContainer">
			<h2>Virtual Monument</h2>
            <p>Explore our WebVR virtual monument of the original exhibit in Frost Library! Click and drag to look around.</p>
            <p>Hover over purple markers to view the sculpted books. Markers with a Micropohone can be clicked to hear a Solidarity Reading.</p>
			
			<div class="embedAframe">
				<a-scene embedded loading-screen="dotsColor: white; backgroundColor: #988FBB">
					<a-assets>
                        <audio id="casteReading" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=FSCoMQCOwY9LyRoLHjO7stHUoMF3pOzn"></audio>
						<img id="caste" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=u8vUeGdOxg0dngwdTnBRIAWvNpc9bzpu">
                        
                        <audio id="othersunsReading" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=ND7YVpXSGZfsrlMiyYlntscFW0d8IowF"></audio>
                        <img id="othersuns" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=Fi0q1BLdTeUtPaAclfId6CW9RKmtSs3f">
                        
                        <img id="becoming" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=VmHX6tdV4NTKp5G9cUCtGI4QybmPqU81">
                        
                        <img id="dissent" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=eCmaw7gsGWByr7I8Xf38SJvnot1kKPGR">
                        
                        <img id="newjimcrow" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=RYAOeLh9b8wIqBbBXIz1rWte32RhL3oD">
                        
                        <img id="belovedkin" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=W7bI8XEfHq3ZcWASiYubqFxIP3GAYzzv">
                        
                        <img id="blackelk" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=n5hNwjGeWKRrdhgtDj2jf1xrVNZ5N6h5">
                        
                        <img id="hela" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=swS98LE4cwRjMXJJO5epn3kKdwAAj4K3">
                        
                        <img id="lick" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=7uSr3JD8K1oh7HHzED7cllkHgSPazhso">
                        
                        <img id="history" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=2GSBeGEOBjRWgs22xn9KRF2QvBXbp4K7">
                        
                        <img id="incidents" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=631rQrPrE0PznfG0nKXmILYdb06UBDIT">
                        
                        <img id="baldwin" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=xSYS4fAMxR6UxPQpkKDLTJDHNsELB9vU">
                        
                        <img id="morrison" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=9ejkMdJ8ZT2maWbEwNbOcK1uDgcjeTFm">
                        
                        <img id="anti" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=TBQyc6CAcqbvgoca2SuyWUsTbLKuWuTg">
                        
                        <audio id="mercyReading" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=lowktHsVixci75dAeP2EjJa0zj2nxbCg"></audio>
                        <img id="mercy" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=zLfsHDfv9rPkVY3TMU847P1hKtO8tF2K">
                        
                        <img id="mic" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=jyDc71NcpiBO1bgmbrTwMcVfLZpEzxwn">
                        <img id="center" crossorigin="anonymous" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=KhiZAUbF7uMvwx2oxAjFAwVLoB285l0s">
					</a-assets>
					
					<a-sky rotation="0 -130 0" src="#center"></a-sky>
                    
                    <a-image id="newjimcrowImage" src="#newjimcrow" width=".45" height=".6" position="-0.26 2.5 -1.7" rotation="20 0 0" opacity="0"></a-image>
                    <a-entity id="newjimcrowEntity"
                              geometry="primitive: plane; width: 2.7; height: .7;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: The New Jim Crow by Michelle Alexander; align: center; width: 3; wrapCount: 22; opacity: 0"
                              position=".5 3.5 -6.5" rotation="20 0 0" opacity="0"></a-entity>
                    <a-circle id="newjimcrowMarker" color="#b69cff" radius="1.6" position="3.1 23 -46" rotation="10 10 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #newjimcrowImage; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #newjimcrowImage; opacity: 0"
                              event-set__makevisible2="_event: mouseenter; _target: #newjimcrowEntity; material.opacity: 1; text.opacity: 1"
                              event-set__makehidden2="_event: mouseleave; _target: #newjimcrowEntity; material.opacity: 0; text.opacity: 0"></a-circle>
                    
                    <a-image id="othersunsImg" src="#othersuns" width=".307" height=".543" position="0.76 2.42 -1." rotation="10 -10  0" opacity="0"></a-image>
                    <a-entity id="othersunsEntity"
                              geometry="primitive: plane; width: 3; height: .4;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: The Warmth of Other Suns by Isabel Wilkerson Read by Meg Roberts Arsenovic; align: center; width: 3; wrapCount: 40; opacity: 0"
                              position="2.2 2.8 -2.5" rotation="20 -30  0" opacity="0"></a-entity>
                    <a-image id="mic" src="#mic" width="4" height="4" position="17.5 25 -46" rotation="10 -10  0" opacity=".9"
                             sound="src: #othersunsReading; on: click; volume: 80"
                             event-set__makevisible="_event: mouseenter; _target: #othersunsImg; opacity: 1"
                             event-set__makehidden="_event: mouseleave; _target: #othersunsImg; opacity: 0"
                             event-set__makevisible2="_event: mouseenter; _target: #othersunsEntity; material.opacity: 1; text.opacity: 1"
                             event-set__makehidden2="_event: mouseleave; _target: #othersunsEntity; material.opacity: 0; text.opacity: 0"></a-image>
                    
                    <a-image id="casteImg" src="#caste" width="2.307" height="2.543" position="-7.5 -2.5 -3.5" rotation="-20 80 0" opacity="0"></a-image>
                    <a-entity id="casteEntity"
                              geometry="primitive: plane; width: 3; height: .8;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: Caste by Isabel Wilkerson Read by Sandor Weiss; align: center; width: 3; wrapCount: 22; opacity: 0"
                              position="-4 -1.8 -1.5" rotation="-20 70 0" opacity="0"></a-entity>
                    <a-image id="mic" src="#mic" width="4" height="4" position="-53 -27 -12.5" rotation="-20 70 0" opacity=".9"
                             sound="src: #casteReading; on: click; volume: 30"
                             event-set__makevisible="_event: mouseenter; _target: #casteImg; opacity: 1"
                             event-set__makehidden="_event: mouseleave; _target: #casteImg; opacity: 0"
                             event-set__makevisible2="_event: mouseenter; _target: #casteEntity; material.opacity: 1; text.opacity: 1"
                             event-set__makehidden2="_event: mouseleave; _target: #casteEntity; material.opacity: 0; text.opacity: 0"></a-image>
                    
                    <a-image id="mercyImg" src="#mercy" width="1.307" height="1.543" position="-6 2.3 2.5" rotation="0 90 0" opacity="0"></a-image>
                    <a-entity id="mercyEntity"
                              geometry="primitive: plane; width: 3; height: .8;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: Just Mercy by Bryan Stevenson Read by Sonya Clark; align: center; width: 3; wrapCount: 25; opacity: 0"
                              position="-6 1.1 2.5" rotation="0 90 0" opacity="0"></a-entity>
                    <a-image id="mic" src="#mic" width="4" height="4" position="-50 -2 3" rotation="0 90 0" opacity=".9"
                             sound="src: #mercyReading; on: click; volume: 50"
                             event-set__makevisible="_event: mouseenter; _target: #mercyImg; opacity: 1"
                             event-set__makehidden="_event: mouseleave; _target: #mercyImg; opacity: 0"
                             event-set__makevisible2="_event: mouseenter; _target: #mercyEntity; material.opacity: 1; text.opacity: 1"
                             event-set__makehidden2="_event: mouseleave; _target: #mercyEntity; material.opacity: 0; text.opacity: 0"></a-image>
                    
                    <a-image id="dissentImg" src="#dissent" width=".307" height=".543" position="-1.2 1.9 -.5" rotation="0 40 0" opacity="0"></a-image>
                    <a-entity id="dissentEntity"
                              geometry="primitive: plane; width: 3; height: .4;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: Dissent by Ralph Young; align: center; width: 3; wrapCount: 22; opacity: 0"
                              position="-6 1.5 -1.5" rotation="0 70 0" opacity="0"></a-entity>
                    <a-circle id="dissentMarker" color="#b69cff" radius="1.8" position="-61 12 -13" rotation="0 70 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #dissentImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #dissentImg; opacity: 0"
                              event-set__makevisible2="_event: mouseenter; _target: #dissentEntity; material.opacity: 1; text.opacity: 1"
                              event-set__makehidden2="_event: mouseleave; _target: #dissentEntity; material.opacity: 0; text.opacity: 0"></a-circle>
                    
                    <a-circle id="morrisonMarker" color="#b69cff" radius="1.8" position="-56 -15 -8" rotation="0 70 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketexviisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #morrison"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: Beloved by Toni Morrison"
                    ></a-circle>
                    
                    <a-image id="becomingImg" src="#becoming" width=".318" height=".534" position="-0.2 1.5 -1.5" rotation="0 30 0" opacity="0"></a-image>
                    <a-entity id="becomingEntity"
                              geometry="primitive: plane; width: 3; height: .4;" material="color: #b69cff; opacity: 0"
                              text="font: roboto; value: Becoming by Michelle Obama; align: center; width: 3; wrapCount: 25; opacity: 0"
                              position="-1.5 .3 -4.5" rotation="0 20 0" opacity="0"></a-entity>
                    <a-circle id="becomingMarker" color="#b69cff" radius="1.8" position="-15 -3 -50" rotation="0 30 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #becomingImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #becomingImg; opacity: 0"
                              event-set__makevisible2="_event: mouseenter; _target: #becomingEntity; material.opacity: 1; text.opacity: 1"
                              event-set__makehidden2="_event: mouseleave; _target: #becomingEntity; material.opacity: 0; text.opacity: 0"></a-circle>
                    
                    
                    <a-circle id="belovedkinMarker" color="#b69cff" radius="1.8" position="12 -3 -50" rotation="0 0 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketexviisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #belovedkin"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: Our Beloved Kin by Lisa Brooks"
                    ></a-circle>
                    
                    <a-circle id="baldwinMarker" color="#b69cff" radius="1.8" position="-10 11 -50" rotation="0 0 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketexviisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #baldwin"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: Collected Essays of James Baldwin"
                    ></a-circle>
                    
                    <a-circle id="lickMarker" color="#b69cff" radius="1.8" position="32 -15 -40" rotation="0 -40 0" opacity="0.9"
                              event-set__makevisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makehidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketexviisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #lick"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: A Straight Lick with a Crooked Stick by Zora Neale Hurston"
                    ></a-circle>
                    
                    <a-circle id="blackelkMarker" color="#b69cff" radius="1.8" position="52 12 -9" rotation="-10 -70 0" opacity="0.9"
                              event-set__makeimgvisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makeimghidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketextvisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #blackelk"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: Black Elk Speaks by John G. Neihardt"
                    ></a-circle>
                    
                    <a-circle id="helaMarker" color="#b69cff" radius="1.8" position="50 -3 18" rotation="-10 -100 0" opacity="0.9"
                              event-set__makeimgvisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makeimghidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketextvisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #hela"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: The Immortal Life of Henrietta Lacks by Rebecca Skloot"
                    ></a-circle>
                    
                    <a-circle id="antiMarker" color="#b69cff" radius="1.8" position="34 -2 25" rotation="-10 -100 0" opacity="0.9"
                              event-set__makeimgvisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makeimghidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketextvisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #anti"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: How to be an Anti-Racist by Ibram X. Kendi"
                    ></a-circle>
                    
                    <a-circle id="historyMarker" color="#b69cff" radius="1.8" position="28 -19 25" rotation="-10 -115 0" opacity="0.9"
                              event-set__makeimgvisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makeimghidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketextvisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #history"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: An Indigenous People's History of the United States"
                    ></a-circle>
                    
                    <a-circle id="incidentsMarker" color="#b69cff" radius="1.8" position="30 9 40" rotation="20 -130 0" opacity="0.9"
                              event-set__makeimgvisible="_event: mouseenter; _target: #sculptedImg; opacity: 1"
                              event-set__makeimghidden="_event: mouseleave; _target: #sculptedImg; opacity: 0"
                              event-set__maketextvisible="_event: mouseenter; _target: #textCaption; material.opacity: 1; text.opacity: 1"
                              event-set__maketexthidden="_event: mouseleave; _target: #textCaption; material.opacity: 0; text.opacity: 0"
                              event-set__changeimg="_event: mouseenter; _target: #sculptedImg; src: #incidents"
                              event-set__changetext="_event: mouseenter; _target: #textCaption; text.value: Incidents in the Life of a Slave Girl by Harriet Jacobs"
                    ></a-circle>
                    
                    <a-camera>
						<a-cursor
							id="cursor"
                            position="0 0 -1"
                            material="color: white; shader: flat"
                            animation__click="property: scale; from: 1 1 1; to: 2 2 2; easing: easeInCubic; dur: 150; startEvents: click"
							animation__clickreset="property: scale; to: 1 1 1; dur: 1; startEvents: animationcomplete__click"
							animation__fusing="property: scale; from: 2 2 2; to: 1 1 1; easing: easeInCubic; dur: 150; startEvents: fusing">
                        </a-cursor>
                        
                        <a-image id="sculptedImg" src="#blackelk" width="1.318" height="1.534" position="1 -.5 -2.5" rotation="0 0 0" opacity="0"></a-image>
                        <a-entity id="textCaption"
                                  geometry="primitive: plane; width: 3; height: 1;" material="color: #b69cff; opacity: 0"
                                  text="font: roboto; value: Black Elk Speaks by John G. Neihardt; align: center; width: 3; wrapCount: 16; opacity: 0"
                                  position="2.3 -3.5 -5.5" rotation="0 0 0" opacity="0"></a-entity>
                        
					</a-camera>
				</a-scene>
			</div>
		</div>
	</div>
</template>

<script>
 
	export default {
		name: "VirtualMonument"
	}
</script>

<style lang="scss" scoped>
	#VirtualMonument {
		max-width: 1400px;
		margin: 0 auto;
		width: 90%;
	}
	
	.embedAframe {
		width: 100%;
		height: 800px;
		padding-bottom: 40px;
	}
 

</style>