<template>
    <div class="container">
        <a :href="currentBookshelf.book1.bookshop" target="_blank">
            <div class="book books-1" :style="{ backgroundImage: 'url(' + book1Cover + ')' }"></div>
        </a>
        <div class="description mobileOnly">
            <p class="title">{{ currentBookshelf.book1.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book1.author }}</p>
        </div>
        <a :href="currentBookshelf.book2.bookshop" target="_blank">
            <div class="book books-2" :style="{ backgroundImage: 'url(' + book2Cover + ')' }"></div>
        </a>
        <div class="description mobileOnly">
            <p class="title">{{ currentBookshelf.book2.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book2.author }}</p>
        </div>
        <a :href="currentBookshelf.book3.bookshop" target="_blank" v-if="currentBookshelf.book3.bookTitle">
            <div class="book books-3" :style="{ backgroundImage: 'url(' + book3Cover + ')' }"></div>
        </a>
        <div class="description mobileOnly" v-if="currentBookshelf.book3.bookTitle">
            <p class="title">{{ currentBookshelf.book3.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book3.author }}</p>
        </div>
        <a :href="currentBookshelf.book4.bookshop" target="_blank" v-if="currentBookshelf.book4.bookTitle">
            <div class="book books-4" :style="{ backgroundImage: 'url(' + book4Cover + ')' }"></div>
        </a>
        <div class="description mobileOnly" v-if="currentBookshelf.book4.bookTitle">
            <p class="title">{{ currentBookshelf.book4.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book4.author }}</p>
        </div>
    </div>
    <div class="wood"></div>
    <div class="titles">
        <div class="description">
            <p class="title">{{ currentBookshelf.book1.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book1.author }}</p>
        </div>
        <div class="description">
            <p class="title">{{ currentBookshelf.book2.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book2.author }}</p>
        </div>
        <div class="description" v-if="currentBookshelf.book3.bookTitle">
            <p class="title">{{ currentBookshelf.book3.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book3.author }}</p>
        </div>
        <div class="description" v-if="currentBookshelf.book4.bookTitle">
            <p class="title">{{ currentBookshelf.book4.bookTitle }}</p>
            <p class="author">{{ currentBookshelf.book4.author }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bookshelves",
        props: ['currentBookshelf'],
        data() {
            return {
                book1Cover: this.currentBookshelf.book1.cover,
                book2Cover: this.currentBookshelf.book2.cover,
                book3Cover: this.currentBookshelf.book3.cover,
                book4Cover: this.currentBookshelf.book4.cover,
            }
        }
    }
</script>

<style scoped lang="scss">
    body {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wood {
        background-image: url("https://solidaritybookproject.com/solidaritylibrary/wl/?id=tLE7cC4g9iEt2w0irnbwVZqnpeD57sG9");
        height: 30px;
        width: 100%;
        border-radius: 10px;
        @media only screen and (max-width: 800px) {
            display: none;
        }
    }

    .container {
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px;
        justify-items: center;
        width: 100%;
        @media only screen and (max-width: 950px) {
            grid-template-columns: 1fr;
        }
    }

    .titles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px;
        justify-items: center;
        padding-bottom: 80px;

        p {
            margin-top: 8px;
            text-align: center;
        }
        @media only screen and (max-width: 950px) {
            display: none;
        }
    }

    .mobileOnly {
        text-align: center;
        display: none;
        @media only screen and (max-width: 950px) {
            display: block;
        }
    }

    .box-inner {
        z-index: 99;
        width: 900px;
        display: flex;
        align-items: center;
        position: relative;
        top: 350px;
        overflow: hidden;
        background-color: #fff;
    }

    .book {
        width: 180px;
        height: 255px;
        background-color: #988FBB;
        transition: all .3s ease-in-out;
        transform-origin: left center 0;
        transform-style: preserve-3d;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        -webkit-backface-visibility: hidden;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(93, 90, 90, 0.55);
    }

    .container .book::after {
        content: " ";
        display: block;
        opacity: 0;
        width: 180px;
        height: 255px;
        position: relative;
        left: 8px;
        transition: all .3s ease;
    }

    .container .book::before {
        content: " ";
        z-index: 999;
        display: block;
        width: 20px;
        height: 100px;
        opacity: 0;
        position: absolute;
        top: -12px;
        right: 8px;
        transition: all .25s;
        background-image: url("https://solidaritybookproject.com/solidaritylibrary/wl/?id=n6i4G2Sf31fv3q3F5RjCzsT2LnwzHHW3");
        background-size: 16px;
        background-repeat: no-repeat;
    }

    /*------ background-pic ------*/

    .books-1 {
        background-size: 180px 255px;
    }

    .books-2 {
        background-size: 180px 255px;
    }

    .books-3 {
        background-size: 180px 255px;
    }

    .books-4 {
        background-size: 180px 255px;
    }

    /* ----- book-blur ----- */
    .book-blur {
        filter: blur(30px)
    }

    .box-inner .book {
        margin-left: 90px;
        position: relative;
        top: -194px;
    }

    /* ----- hover ----- */
    .book:hover {
        cursor: pointer;
        transform: rotateY(-28deg) rotateZ(-2deg) scale(1.02);
        -webkit-backface-visibility: hidden;
        box-shadow: 1px 3px 2px #6D648E, 20px 8px 0 #eee2cd, 30px 15px 36px rgba(7, 7, 7, 0.45);
    }

    .book:hover::after {
        content: " ";
        display: block;
        opacity: 1;
        width: 172px;
        height: 255px;
        position: relative;
        left: 8px;
        background: linear-gradient(-180deg, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, 0) 60%);
    }

    .book:hover::before {
        transform: translateY(9px);
        opacity: 1;
    }

    .description {
        p {
            margin: 0;
        }

        .title {
            font-size: 24px;
        }

        .author {
            font-size: 20px;
            color: #6D648E;
        }
    }
</style>