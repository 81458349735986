<template>
    <div class="transitionPage">
        <div id="Essay">
            <div class="preTitle">
                <h2>15 Writers on Solidarity</h2>
                <router-link :to="{ name: 'Writers'}">
                    <h3 class="return"><i class="large material-icons">arrow_back</i>Return to full list of writers</h3>
                </router-link>
            </div>
            <div class="title">
                <h2>{{ person.title }}</h2>
                <h3>by {{ person.name }}</h3>
            </div>
            <div v-html="person.essay"></div>
            <div class="bioGrid">
                <img :src="person.photo" alt="" class="profile">
                <div class="bioText">
                    <p class="name">{{ person.name }}</p>
                    <div class="w-bio" v-html="person.bio"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Essay",
        data() {
            return {
                people: [],
                person: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/writers-Grid.json", false);
            request.send(null)
            this.people = (JSON.parse(request.responseText));

            for (var i = 0; i < this.people.length; i++) {
                if (this.people[i].slug === this.$route.params.writer_slug) {
                    this.person = this.people[i]
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    #Essay {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
    }

    .bioGrid {
        background-color: #6D648E;
        color: white;
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 40px;
        padding: 40px;
        margin: 40px;

        @media only screen and (max-width: 800px) {
            grid-template-columns: 100%;
            grid-gap: 20px;
            padding: 20px;
            margin: 20px;
        }
    }


    .profile {
        width: 350px;
        height: 350px;
        justify-self: center;
        @media only screen and (max-width: 800px) {
            width: 200px;
            height: 200px;
        }
    }

    .name {
        margin-top: 0;
        font-weight: bold;
    }

    .preTitle {
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        h2, h3 {
            margin: 0;
            text-align: right;
            color: #6D648E;
        }

        h2 {
            font-size: 36px;
        }

        h3 {
            font-size: 22px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .title {
        display: flex;
        flex-direction: column;

        h2, h3 {
            margin: 0;
        }
    }

    .return {
        display: flex;
        justify-content: right;
        align-items: center;
        padding-bottom: 10px;
    }

    .material-icons {
        padding-right: 6px;
    }
</style>

<style lang="scss">
    .w-bio {
        p {
            font-size: 20px;
        }
        a {
            color: #FFFD82;
        }
    }
    .amosu {
        margin-left: 40px;

        pre {
            font-family: 'freight-text-pro', serif;
            font-size: 20px;
            line-height: 0.25;
        }
    }

    .moss {
        h4 {
            margin-bottom: -6px;
        }

        p {
            font-size: 22px;
        }

        .blockquote {
            margin-left: 80px;

            p {
                margin: 0;
            }
        }

        .quote {
            font-style: italic;
        }

        .attribute {
            text-align: right;
        }
    }

    .mohammed {
        .bodyText {
            padding: 20px 0;

            p {
                font-size: 22px;
                text-indent: 50px;
                line-height: 1.75;
                margin: 0;
            }
        }

        sup {
            a {
                font-weight: normal;
            }
        }

        .footnotes {
            border-top: 1px solid black;
            padding: 20px 0 40px 0;

            p {
                font-size: 18px;
                margin: 0;
            }
        }
    }

    .eubanks {
        padding-top: 20px;

        p {
            font-size: 22px;
            text-indent: 50px;
            line-height: 1.75;
            margin: 0;
        }

        .poem {
            padding: 10px 0;
            font-style: italic;
            margin-left: 80px;
        }
    }

    .jamar {
        padding-top: 20px;

        p {
            font-size: 22px;
            text-indent: 50px;
            line-height: 1.75;
            margin: 0;
        }

        .blockquote {
            margin-left: 80px;

            p {
                margin: 20px 0;
                text-indent: 0;
                font-style: italic;
            }
        }
    }

    .asmith, .ssmith, .hezekiah, .basu, .vigil, .jsmith {
        p {
            font-size: 22px;
        }
    }

    .lawson {
        p {
            font-size: 22px;
        }

        .transcribed {
            font-size: 18px;
            font-style: italic;
        }

        pre {
            font-family: 'freight-text-pro', serif;
            font-size: 20px;
            margin: 0;
            line-height: 1.15;
        }
    }

    .perry {
        .transcribed {
            font-size: 18px;
            font-style: italic;
        }
    }

    .brooks {
        p {
            margin-top: 0;
            font-size: 22px;
        }

        .title {
            margin-top: 22px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0;
        }

        .subtitle {
            margin: 0 0 22px 0;
            text-align: center;
        }

        .poem {
            max-width: 1000px;
            margin: 0 auto;

            pre {
                font-family: 'freight-text-pro', serif;
                font-size: 22px;
                margin: 0;
                line-height: 1.15;
            }
        }
    }

    .hall {
        padding: 20px 0;

        p {
            font-size: 22px;
            text-indent: 50px;
            line-height: 1.75;
            margin: 0;
        }
    }
</style>