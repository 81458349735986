<template>
    <div class="transitionPage">
        <div id="Writers" class="pageContainer">
            <h2>15 Writers on Solidarity</h2>
            <div class="grid">
                <router-link :to="{ name: 'Essay', params: { writer_slug: writer.slug }}" v-for="writer in writers"
                             :key="writer.slug">
                    <div class="writerBox">
                        <img :src="writer.photo" alt="" class="profile">
                        <div class="textInfo">
                            <h3>{{ writer.title }}</h3>
                            <p>by {{ writer.name }}</p>
                            <p class="read">Read more...</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Writers",
        data() {
            return {
                writers: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/writers-Grid.json", false);
            request.send(null)
            this.writers = (JSON.parse(request.responseText));
        },
    }
</script>

<style scoped lang="scss">
    #Writers {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
    }

    .comingSoon {
        text-align: center;
        font-family: 'freight-sans-pro', sans-serif;
        font-weight: bold;
        font-size: 36px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 80px;
        padding-bottom: 40px;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 1fr;
        }
    }

    .writerBox {
        display: flex;
        flex-direction: row;
        grid-gap: 40px;
        transition: .5s;
        @media only screen and (max-width: 1100px) {
            flex-direction: column;
        }
        @media only screen and (max-width: 800px) {
            flex-direction: row;
            grid-gap: 20px;
        }
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            grid-gap: 20px;
        }
        &:hover .profile {
            transition: .5s;
            box-shadow: 12px 12px 0 #988FBB;
        }
    }

    .profile {
        width: 300px;
        height: 300px;
        @media only screen and (max-width: 750px) {
            width: 175px;
            height: 175px;
        }
    }

    .textInfo {
        h3 {
            margin: 0;
            line-height: 1;
            @media only screen and (max-width: 800px) {
                font-size: 24px;
            }
        }

        p {
            margin: 0;
            padding-top: 4px;
            color: black;
            // font-weight: normal;
            @media only screen and (max-width: 800px) {
                font-size: 22px;
            }
        }
    }

    .read {
        font-size: 20px;
        color: #988FBB;
        font-weight: normal;
    }
</style>