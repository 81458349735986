<template>
    <div class="my-page">
        <div class="pageContent">
            <iframe :src="currentReading.youtubeLink"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen v-if="currentReading.youtubeLink"></iframe>
            <h3 class="name">{{ currentReading.name }}</h3>
            <p>reads from</p>
            <p class="bookTitle">"{{ currentReading.bookTitle }}"</p>
            <p>by <span class="author">{{currentReading.author}}</span></p>
        </div>
    </div>
    <div class="my-page">
        <div class="pageContentText">
            <p class="info">Solidarity Reading submitted by {{ currentReading.name }}</p>
            <p class="info">An excerpt from "{{ currentReading.bookTitle }}"</p>
            <p>...{{ currentReading.excerpt}}...</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReadingPages",
        props: ['currentReading']
    }
</script>

<style scoped lang="scss">
    iframe {
        width: 80%;
        height: 50%;
    }
    .my-page {
        background-color: #fdfaf7;
        border: solid 1px #c2b5a3;
        overflow: hidden;
    }

    .pageContent {
        width: 95%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        h3 {
            margin-bottom: 0;
            font-size: 24px;
        }

        p {
            margin: 0;
            font-size: 18px;
        }

        .bookTitle {
            color: #988FBB;
            font-size: 24px;
            margin: 8px 0;
            font-weight: bold;
            text-align: center;
        }
    }

    .pageContentText {
        width: 85%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        p {
            font-size: 20px;
        }
    }
    .info {
        text-align: center;
        font-weight: bold;
    }

    .my-page.--right {
        box-shadow: inset 7px 0 18px -7px rgb(0 0 0 / 30%);
    }

    .my-page.--left {
        box-shadow: inset -7px 0 18px -7px rgb(0 0 0 / 30%);
    }

    .page__number {
        position: absolute;
        bottom: 14px;
        text-align: center;
    }

    .author {
        font-weight: bold;
        font-size: 20px;
    }

</style>