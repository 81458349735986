<template>
    <div class="transitionPage">
        <div class="section1">
            <h2>Start Your Own Project</h2>
            <p>The Solidarity Book Project was organized as a method of reverse fundraising in order to decolonize and redistribute a small portion of Amherst College’s wealth. We hope our practices can be a model for other institutions to bring together art, activism, and solidarity to decolonize wealth in support of Black & Indigenous communities.</p>
            <p>What we’ve outlined below are multiple ways an artist, educator, institution, or organization can use the model of the Solidarity Book Project to positively impact their community:</p>
        </div>
        <div class="modules">
            <div class="module">
                <h3>Book Knowledge</h3>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=w5PrNE6Y1Y1m9NTHhHrZ1f97TK3LXXnA" class="moduleImg">
                <p class="moduleText">Share reflections and readings on social media to foster the importance of book knowledge and amplify the contribution of writers to our understanding of solidarity</p>
            </div>
            <div class="module">
                <h3>Community Building</h3>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=zGhnuzMaWLNSkKrzYZSJkNfePTw5jmuk" class="moduleImg">
                <p class="moduleText">Bring together a community of people to reflect on the role of solidarity in their lives by exchanging stories and ideas</p>
            </div>
            <div class="module">
                <h3>Artwork</h3>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=5ocNSp0Qbgrk3oPAgyCMNCiR9iwmdNjp" class="moduleImg">
                <p class="moduleText">Sculpt solidarity books to create unique works of art that speak to the connections between solidarity and book knowledge</p>
            </div>
            <div class="module">
                <h3>Decolonizing Wealth</h3>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=HLPtY2acY5bgjvpIhOHS01COCBwYFKPf" class="moduleImg">
                <p class="moduleText">Partner with institutions who have benefited from colonialism to match community participation with gifts and donations to organizations supporting Black & Indigenous communities</p>
            </div>
            <div class="module">
                <h3>Public Monument</h3>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=WIkfbii5H7S0UUqvp8315wpZjeSMkcJn" class="moduleImg">
                <p class="moduleText">Create a public monument of your community’s artwork, reflections, and readings that represents your collective understanding of solidarity and commitment to Black & Indigenous communities</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BringToYourInstitution',
    }
</script>

<style scoped lang="scss">
	.section1 {
		max-width: 1400px;
        width: 90%;
        margin: 0 auto;
	}

	.modules {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		max-width: 1400px;
		margin: 0 auto;
		padding-top: 40px;
        grid-gap: 20px;
	}

	.module {
		// background-color: #2D3047;
        background-color: #6D648E;
		display: flex;
		flex-direction: column;
		width: 350px;
		margin-bottom: 80px;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.45);
		margin-left: 40px;
        @media only screen and (max-width: 800px) {
            width: 325px;
            margin-left: 0;
        }
	}

	.moduleImg {
		margin: 0 auto;
		width: 200px;
	}
	
	h3 {
		text-align: center;
		color: white;
		font-size: 32px;
		margin: 18px 0;
		font-weight: normal;
	}

	.moduleText {
		color: white;
		text-align: center;
		line-height: 1.5;
		padding: 0 20px 20px 20px;
        font-size: 20px;
	}
</style>