<template>
    <div class="transitionPage">
<!--        <Nav/>-->
        <div class="section1">
            <div id="OW" class="overview">
                <div class="rowSplit">
                    <div class="rightColumn">
                        <h2>About</h2>
                        <p>The Solidarity Book Project, envisioned by Sonya Clark, celebrates the power of art to change the
                            world. SBP models how our reflections, readings, and art can make a material difference and
                            directly support Black and Indigenous communities. The project is built upon partnership with
                            Amherst College, who pledged to match participation in the project through gifts to nonprofits
                            serving Black & Indigenous communities.</p>
                        <p>This participation took the form of three calls to action: #SolidarityReflection called on
                            participants to reflect on what solidarity meant to them and to share moments they had witnessed
                            solidarity in their lives. #SolidarityReading called on participants to read from books that
                            taught them about solidarity. #SolidaritySculpting called on participants to sculpt the iconic
                            symbol of the solidarity fist in books that taught them about solidarity. Each of these actions
                            are ways to raise awareness, celebrate Black & Indigenous authors, and create material objects
                            that represent our commitment to solidarity.
                        </p>
                        <p>All of these responses were brought together as a collective monument to solidarity — a work of
                            art that represents the cumulative power of community art and action. This monument was
                            physically realized in an exhibit at Frost Library in Amherst College from September 2021 to
                            November 2021. In addition, all of the materials submitted to the project are also gathered here
                            on this site, serving as a virtual archive and monument to the community of solidarity formed
                            around the project.</p>
                        <p>Through the participation of thousands of people across the world, we raised $100,000 in matched
                            gifts from Amherst College. This funding will be distributed to nonprofits serving Black &
                            Indigenous communities, with a focus on supporting literacy, education, and book knowledge.</p>
                        <p>The Solidarity Book Project is a replicable model for reverse fundraising — a process where a
                            community’s creative and reflective efforts are matched by institutional donations. If you want
                            to do this solidarity work and raise funds for other marginalized communities dear to your
                            heart, feel free to use our project as a guide.</p>
                    </div>
                </div>
            </div>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=AxbHrCja5rMUBaIUPxhVU5itsBeaWUD7" alt="Solidarity fist made out of layered text from solidarity reflections" class="fistImg">
        </div>
<!--        <Footer/>-->
    </div>
</template>

<script>
    // import Footer from "@/aframecomponents/Footer";
    // import Nav from "@/aframecomponents/Nav2";

    export default {
        name: 'About-2',
        // aframecomponents: {Nav, Footer}
    }
</script>

<style scoped lang="scss">
    a {
        color: black;
        text-decoration: none;
    }

    .overview, .team, .quote, .acknowledgements {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
    }

    .aboutVid {
        margin-top: 32px;
        border: 6px solid #988FBB;
        box-sizing: border-box;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
    }

    .menu {
        position: fixed;
        background-color: rgba(256, 256, 256, 1);
        padding: 6px 12px 6px 12px;
        margin-top: 120px;

        :hover {
            color: #29A092;
        }

        .menuItems {
            cursor: pointer;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .section1 {
        padding-bottom: 40px;
        flex-grow: 1;
    }

    .section4, .acknowledgements {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .fistImg {
        display: flex;
        justify-content: center;
        width: 400px;
        margin: 0 auto;
        max-width: 90%;
    }
</style>