<template>
    <div class="transitionPage">
        <div class="section1">
            <h2>Solidarity Reading</h2>
            <p>#SolidarityReading called on participants to read from books that have taught them something about solidarity. Participants
                submitted videos, audio files, and text excerpts from history books, novels, academic texts, cookbooks, and
                more. Below you can find each submission received by the Solidarity Book Project.</p>
            <div class="hintContainer">
                <a href="https://www.youtube.com/channel/UCgCR08Mr42wl3DHnarBhAXw" target="_blank">
                    <span class="youtubeHint">Click here to view the all of the readings on Youtube!</span>
                </a>
            </div>
            <div class="flipContainer">
                <div id="book">
                    <ReadingPages v-for="reading in readings" :key="reading.id" :currentReading="reading"></ReadingPages>
                </div>
            </div>
            <div class="flipInsContainer">
                <p class="flipIns" @click="flipPrevPage">Previous Page</p>
                <p class="flipIns" @click="flipNextPage">Next Page</p>
            </div>
            <p class="instructions" style="padding-top: 40px; margin-bottom: 0;">Below are all of the books submitted to the #SolidarityReading call to action. <br/>Select a book to find it on Bookshop.org or other sellers:</p>
            <div class="bookshelfContainer">
                <Bookshelves v-for="bookshelf in bookshelves" :key="bookshelf.id" :currentBookshelf="bookshelf"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Bookshelves from "../../components/archiveComponents/Bookshelves";
    import ReadingPages from "../../components/archiveComponents/ReadingPages";

    import {PageFlip} from 'page-flip';

    export default {
        name: 'Readings',
        components: {ReadingPages, Bookshelves},
        data() {
            return {
                readings: [],
                bookshelves: [],
                pageFlip: null
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/readings-Grid-2.json", false);
            request.send(null)
            this.readings = (JSON.parse(request.responseText));

            const request2 = new XMLHttpRequest();
            request2.open("GET", "/bookshelves.json", false);
            request2.send(null)
            this.bookshelves = (JSON.parse(request2.responseText));
        },
        mounted() {
            this.pageFlip = new PageFlip(document.getElementById('book'),
                {
                    // width: 400,
                    // height: 600,
                    drawShadow: true,
                    maxShadowOpacity: 0.2,
                    width: 550, // base page width
                    height: 733, // base page height

                    size: "stretch",
                    // set threshold values:
                    minWidth: 315,
                    maxWidth: 600,
                    minHeight: 420,
                    maxHeight: 1350,

                    mobileScrollSupport: false // disable content scrolling on mobile devices
                }
            );

            this.pageFlip.loadFromHTML(document.querySelectorAll('.my-page'));
        },
        methods: {
            flipNextPage() {
                this.pageFlip.flipNext('top')
            },
            flipPrevPage() {
                this.pageFlip.flipPrev('top')
            }
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
    }

    .section1 {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
    }

    #book {
        margin: 40px auto;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.4);
    }

    .instructions {
        // color: #6D648E;
        font-weight: bold;
        padding-top: 12px;
        margin-bottom: 4px;
        text-align: center;
        font-family: 'freight-sans-pro', sans-serif;
    }
    .flipInsContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 10%;
        margin-top: -20px;
        grid-gap: 10px;
        @media only screen and (max-width: 700px) {
            padding: 0 5%;
        }
    }
    .flipIns {
        cursor: pointer;
        background-color: #988FBB;
        border-radius: 20px;
        padding: 6px 12px;
        color: white;
        font-weight: bold;
        white-space: nowrap;
        @media only screen and (max-width: 700px) {
            font-size: 20px;
        }
    }

    .hintContainer {
        margin: 0 auto;
        text-align: center;
        display: block;
        font-family: 'freight-sans-pro', sans-serif;
    }
    .youtubeHint {
        text-align: center;
        color: #6D648E;
        font-size: 26px;
        font-weight: bold;
    }

    .bookTop {
        color: #484848;
        font-size: 14px;
        position: absolute;
        top: 7px;
        text-align: center;
        margin: 0;
    }

    .bookGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;

        p {
            font-size: 30px;

            &:hover {
                color: #988FBB;
            }
        }
    }

    .bookshelfContainer {
        padding: 40px 0;
    }
</style>