<template>
    <div class="transitionPage">
        <div class="section1">
            <h2>How to Sculpt a Solidarity Book</h2>
            <div class="cols">
                <div class="col1">
                    <h3>Materials Needed</h3>
                    <ul>
                        <li>A book that has taught you something about solidarity, at least 280 pages long</li>
                        <li>A pencil</li>
                        <li>An eraser</li>
                        <li>Scissors</li>
                        <li>Printed copy of the Solidarity Book Template [<a href="https://solidaritybookproject.com/solidaritylibrary/wl/?id=OVGcFpyz04YmccOJVaJoyZi0jJKUsjLj" target="_blank">click here to download</a>]</li>
                    </ul>
                </div>
                <div class="col2">
                    <h3>Additional Info</h3>
                    <ul>
                        <li>It takes most people 2-4 hours to craft a solidarity book</li>
                        <li>It can be done across multiple sittings</li>
                        <li>Listening to an audiobook is a great way to pass the time!</li>
                    </ul>
                </div>
            </div>
            <hr>
            <h3>Step by Step Instructions</h3>
            <p>Video Tutorial</p>
            <iframe class="youtubeInstructions" width="835" height="470" src="https://www.youtube-nocookie.com/embed/KjOk_MOT3mw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <h3 class="step">Step 1.</h3>
            <div class="cols">
                <div>
                    <p>Find the center of the book and place the template between the pages</p>
                    <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=OEk1wtRb1569XTCZCnuzzq3YOj4LIXvT" width=500>
                </div>
                <div>
                    <p>Align the vertical outer edge of the page with the centerline of the template (marked by arrows on the template).</p>
                    <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=oQ5G4k3UjAN1BGRumY4231CMbEcPCwHu" width=500>
                </div>
            </div>
            <div class="tipSection">
                <p class="tip">TIP: Fold the top edge of the template over the pages of the book to secure it in place!</p>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=UGig5MrYP2bzepkySJIEgOYMUnoPEZzI" width=300>
            </div>
            <h3 class="step">Step 2.</h3>
            <p>Make a pencil mark on the book page at the beginning and ending of each vertical line of the template. These markers will tell you where to cut the page later on!</p>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=QsNClZWLtxCvi8uzosCJHm4PSDgfBDL7" width=700>
            <div class="tipSection">
                <p class="tip">TIP: Check off the top of the line you just completed on thetemplate. Do this each time so you don’t lose your place.</p>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=u7YSDsC5ZmaG3RhLp4WfRE6aPeQIqlam" width=300>
            </div>
            <h3 class="step">Step 3.</h3>
            <p>Move the template to the preceding page, repeating Step 2 as you move towards the beginning of the book.</p>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=9XBIDHshx8HmkyV3Chuf3xmyodadjczY" width=700>
            <h3 class="step">Step 4.</h3>
            <p>When you have finished marking the entire left half of the template, go back to the middle of your book. Repeat the process, this time, working on the right half of the template and marking each page as you move toward the back of your book.</p>
            <div class="cols">
                <div>
                    <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=DKdd2XsLkFT5UyjUrIdMpTpmbSsAfdCA" width=500>
                </div>
                <div>
                    <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=HCfbpbPGADDt84yaIBXarsWXCEIxzefg" width=500>
                </div>
            </div>
            <div class="tipSection">
                <p class="tip">TIP: You may need to fold your template horizontally to fit the template into the depth of your book as you work towards the back cover.</p>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=17ZdkMl25ahCJ7BXmADmSaMJ0AhNkPRs" width=300>
            </div>
            <h3 class="step">Step 5.</h3>
            <p>Once you have marked the entire template onto the book, it’s time to cut!</p>
            <p>Starting at the first marked page, cut at each mark only as deep as the margin. This creates a series of tabs.</p>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=VRrZ0dx2VAlEDK99XAPeuCowmcUhVvTM" width=700>
            <h3 class="step">Step 6.</h3>
            <p>Working from the top of the page, fold the first tab toward the center of your book. Skip the next tab. Alternate folding tabs for the rest of the page.</p>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=hAyIPMxW6ZrYxjNwtHrdLiwp8YQKZ0yW" width=500>
            <h3 class="step">Step 7.</h3>
            <p> Repeat Step 6 on all marked pages of the book until the solidarity fist is complete.</p>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=w2pdPXpnoujRmiKoEzYnwhglT9eNNkdI" width=500 style="padding-bottom: 40px;">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SculptingInstructions',
    }
</script>

<style scoped lang="scss">
	ul {
		padding-inline-start: 20px;
        font-size: 24px;
	}
    h3 {
        margin-bottom: 0;
    }
    img {
        @media only screen and (max-width: 800px) {
            width: 350px;
        }
    }
	.section1 {
		max-width: 1400px;
        margin: 0 auto;
        width: 90%;
	}
    .banner {
        width: 100%;
        object-fit: cover;
        height: 250px;
        display: flex;
        justify-content: center;
        margin: 0 auto
    }

	.cols {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 40px;
        h3 {
            margin-top: 0;
        }
        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
	}

	.tipSection {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
            padding: 10px 0;
            p {
                margin: 10px 0;
            }
        }
	}
	.tip {
		width: 250px;
		height: 100%;
		background-color: #988FBB;
		color: white;
		padding: 20px;
		font-size: 20px;
        font-weight: bold;
		margin-right: 40px;
        @media only screen and (max-width: 800px) {
            padding: 10px;
            margin-right: 0;
        }
	}

    .youtubeInstructions {
        width: 835px;
        height: 475px;
        @media only screen and (max-width: 800px) {
            width: 560px;
            height: 315px;
        }
        @media only screen and (max-width: 500px) {
            width: 330px;
            height: 186px;
        }
    }
</style>