<template>
    <div class="transitionPage">
        <div id="Press" class="pageContainer">
            <h2>Press</h2>
            <div class="pressEntry">
                <h3 class="title">"Sonya Clark Uses Common Fibers to Weave Together Craft, Community, and Activism"</h3>
                <p class="whereWhen">Colossal | November 2023 | <a href="https://www.thisiscolossal.com/2023/11/sonya-clark-community-art/" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"Sonya Clark:  We Are Each Other"</h3>
                <p class="whereWhen">High | October 2023 | <a href="https://high.org/exhibition/sonya-clark/" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"Review: Solidarity Book Project"</h3>
                <p class="whereWhen">Reviews in DH | April 2022 | <a href="https://reviewsindh.pubpub.org/pub/solidarity-book-project/release/1" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"Solidarity for Posterity"</h3>
                <p class="whereWhen">Amherst At 200 | January 2022 | <a href="https://www.amherst.edu/amherst-story/today/amherst-in-pictures/solidarity-for-posterity" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"The Solidarity Book Project: Sonya Clark"</h3>
                <p class="whereWhen">Surface Design Journal | December 2021 | <a href="https://drive.google.com/file/d/1M87bSCGcSpwhGkFp4ijwvSOHIwHhYF42/view?usp=sharing" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"The Solidarity Book Project"</h3>
                <p class="whereWhen">The Common | March 2021 | <a href="https://www.thecommononline.org/solidarity-book-project/" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"The Art of Solidarity"</h3>
                <p class="whereWhen">Amherst College News | February 2021 | <a href="https://www.amherst.edu/news/news_releases/2021/2-2021/the-art-of-solidarity" target="_blank" class="readmore">read more</a></p>
            </div>
            <div class="pressEntry">
                <h3 class="title">"Artist, Sonya Clark, Launches “Solidarity Book Project”, A Racism, History & Social Justice Initiative"</h3>
                <p class="whereWhen">Newswise | November 2020 | <a href="https://www.newswise.com/articles/artist-sonya-clark-launches-solidarity-book-project-a-racism-history-social-justice-initiative" target="_blank" class="readmore">read more</a></p>
            </div>
            <img class="btmImg" src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=5Jl14DWJwQHhNsR4wX18WR9bDi1F5Pat" alt="Sculpting Materials">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Press",
    }
</script>

<style scoped>
    #Press {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
    }
    .pressEntry {
        padding: 20px 0;
    }
    .title {
        font-family: 'freight-sans-pro', sans-serif;
        margin: 0;
        font-size: 26px;
    }
    .whereWhen {
        margin: 0;
    }
    .readmore {
        font-family: 'freight-sans-pro', sans-serif;
        color: #7e74a4;
        font-weight: bold;
        font-size: 20px;
        font-variant: small-caps;
    }

    .btmImg {
        width: 80%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 40px 0;
        max-height: 450px;
        object-fit: cover;
        object-position: center;
    }
</style>