<template>
    <div class="pageTransition">
        <div id="teamExpanded">
            <h2 class="pageTitle">Team</h2>
            <div class="teamGrid">
                <router-link :to="{ name: 'Person', params: { person_slug: person.slug }}" v-for="person in people" :key="person.slug">
                    <div class="person">
                        <img class="teamImg"
                             :src="person.img">
                        <h3 class="teamName">{{ person.name }}</h3>
                        <p class="teamTitle">{{ person.title }}</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'TeamExpanded',
        data() {
            return {
                people: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/sbpteam.json", false);
            request.send(null)
            this.people = (JSON.parse(request.responseText));
        },
    }
</script>

<style scoped lang="scss">
	#teamExpanded {
		max-width: 1400px;
        width: 90%;
        margin: 0 auto;
	}
    a {
        text-decoration: none;
        color: black;
    }

    .section3 {
        padding-bottom: 40px;
    }

    .pageTitle {
        @media only screen and (max-width: 700px) {
            text-align: center;
        }
    }

    .teamGrid {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: 33% 33% 33%;
        padding-bottom: 40px;
        @media only screen and (max-width: 1200px) {
            grid-template-columns: 50% 50%;
        }
        @media only screen and (max-width: 700px) {
            grid-template-columns: 100%;
            justify-items: center;
        }
    }

    .teamImg {
        width: 350px;
        height: 350px;
        padding: 8px;
        object-fit: cover;
        background: url("https://solidaritybookproject.com/solidaritylibrary/wl/?id=QVCGaC7xPUSnNSRoRnHJtirvBZBzui7w") no-repeat;
        background-size: 100%;
        transition: .75s;
        @media only screen and (max-width:1300px) {
            width: 325px;
            height: 325px;
        }
        @media only screen and (max-width:800px) {
            width: 300px;
            height: 300px;
        }
    }

    .teamImg:hover {
        background: #988FBB;
        transition: .75s
    }

    .teamName {
        margin: 0;
    }

    .teamTitle {
        margin: 0;
        font-style: italic;
        color: gray;
        font-size: 20px;
    }


</style>