import {createRouter, createWebHistory} from 'vue-router'


import SculptingInstructions from "../pages/ResourcesPages/SculptingInstructions"
import BringToYourInstitution from "../pages/ResourcesPages/BringToYourInstitution"
import BuyBooks from "../pages/ResourcesPages/BuyBooks"

import About from "../pages/AboutPages/About-2";
import Team from "../pages/AboutPages/TeamExpanded"
import FAQ from "../pages/AboutPages/FAQ"
import Person from "../pages/AboutPages/Person"
import Giving from "../pages/AboutPages/Giving";

// import ProjectArchive from "../pages/ProjectArchive"
import Sculpting from "../pages/ArchivePages/Sculpting"
import ReflectionGrid from "../pages/ArchivePages/ReflectionGrid";
import Reflections from "../pages/ArchivePages/Reflections"
import Readings from "../pages/ArchivePages/Readings"


// import Home2 from '../pages/Home-2.vue'
import HomeLaunch from "../pages/HomeLaunch";
import Writers from "../pages/ArchivePages/Writers";
import Press from "../pages/Press";
import VirtualMonument from "../pages/VirtualMonument";
import MonumentPhotos from "../pages/ArchivePages/MonumentPhotos";
import NotFound from "../pages/NotFound";
import Essay from "../pages/ArchivePages/Essay";
import Acknowledgements from "../pages/AboutPages/Acknowledgements";
import Catalogue from "../pages/Catalogue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeLaunch
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/giving',
        name: 'Giving',
        component: Giving
    },
    {
        path: '/team',
        name: 'Team',
        component: Team
    },
    {
        path: '/acknowledgements',
        name: 'Acknowledgements',
        component: Acknowledgements
    },
    {
        path: '/team/:person_slug',
        name: 'Person',
        component: Person
    },
    // {
    //     path: '/archive',
    //     name: 'ProjectArchive',
    //     component: ProjectArchive
    // },
    {
        path: '/solidarity-sculpting',
        name: 'Sculpting',
        component: Sculpting
    },
    {
        path: '/solidarity-reflection',
        name: 'Reflections',
        component: ReflectionGrid
    },
    {
        path: '/solidarity-reflection/:participant_slug',
        name: 'ReflectionIndividual',
        component: Reflections
    },
    {
        path: '/solidarity-reading',
        name: 'Readings',
        component: Readings
    },
    {
        path: '/15-writers-on-solidarity',
        name: 'Writers',
        component: Writers
    },
    {
        path: '/15-writers-on-solidarity/:writer_slug',
        name: 'Essay',
        component: Essay
    },
    {
        path: '/monument-photos',
        name: 'MonumentPhotos',
        component: MonumentPhotos
    },
    {
        path: '/sculpting-instructions',
        name: 'SculptingInstructions',
        component: SculptingInstructions
    },
    {
        path: '/start-your-own-project',
        name: 'BringToYourInstitution',
        component: BringToYourInstitution
    },
    {
        path: '/bookstores',
        name: 'BuyBooks',
        component: BuyBooks
    },
    {
        path: '/press',
        name: 'Press',
        component: Press
    },
    {
        path: '/catalogue',
        name: 'Catalogue',
        component: Catalogue
    },
    {
        path: '/virtual-monument',
        name: 'VirtualMonument',
        component: VirtualMonument
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {top: 0}
    }
})

export default router
