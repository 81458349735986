<template>
    <div class="transitionPage">
        <div class="section1">
            <router-link :to="{ name: 'Team'}">
                <p class="return"><i class="large material-icons">arrow_back</i>Return to full team list</p>
            </router-link>
            <div class="person">
                <img class="teamImg" :src="person.img">
                <div class="teamText">
                    <h3 class="teamName">{{ person.name }}</h3>
                    <p class="teamTitle">{{ person.title }}</p>
                    <p class="teamWebsite" v-if="person.website">See more of their work: <a :href="person.website"
                                                                                            target="_blank">{{
                            person.website
                        }}</a></p>
                    <div class="teamBio" v-html="person.bio"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Person',
        data() {
            return {
                people: [],
                person: []
            }
        },
        mounted() {
            const request = new XMLHttpRequest();
            request.open("GET", "/sbpteam.json", false);
            request.send(null)
            this.people = (JSON.parse(request.responseText));

            for (var i = 0; i < this.people.length; i++) {
                if (this.people[i].slug === this.$route.params.person_slug) {
                    this.person = this.people[i]
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
        color: black;
    }

    .section1 {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        flex: 1;
    }

    .onePerson {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .person {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: 30% 70%;
        // align-items: center;
        padding-bottom: 40px;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 100%;
        }
    }

    .teamImg {
        width: 350px;
        height: 350px;
        padding: 8px;
        object-fit: cover;
        background: url("https://solidaritybookproject.com/solidaritylibrary/wl/?id=QVCGaC7xPUSnNSRoRnHJtirvBZBzui7w") no-repeat;
        background-size: 100%;
        @media only screen and (max-width: 1300px) {
            width: 325px;
            height: 325px;
        }
        @media only screen and (max-width: 800px) {
            width: 300px;
            height: 300px;
        }
    }

    .teamName {
        margin: 0;
    }

    .teamTitle {
        margin: 0;
        font-style: italic;
        color: gray;
        font-size: 22px;
    }

    .teamWebsite {
        a {
            color: #6D648E;
            font-weight: bold;
        }
    }

    .teamBio {
        line-height: 1.75;
    }

    .return {
        display: flex;
        align-items: center;
        color: #2D3047;
        padding-bottom: 20px;
    }

    .material-icons {
        padding-right: 6px;
    }
</style>