<template>
    <div id="NotFound" class="transitionPage">
        <h1>404</h1>
        <h3>We don't have a page with that URL. Please explore the menu!</h3>
        <h3>If you think something on the site is not working correctly, let us know at solidaritybook [at] gmail [dot] com</h3>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>
    #NotFound {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
        text-align: center;
    }

    h3 {
        font-family: 'freight-sans-pro', sans-serif;
        font-weight: normal;
    }
</style>