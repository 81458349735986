<template>
    <div class="transitionPage">
        <div id="MonumentPhotos">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=kgcE0TKFZJU4N85yK4xSf3Lk9oN13fvE"
                 alt="Sonya Clark speaks to students at the Solidarity Monument in Frost Library"
                 class="bannerImg">
            <h2>The Solidarity Monument brought together all of the reflections, readings, and book sculptures submitted
                to
                the project into a collaborative artwork at Robert Frost Library in Amherst College</h2>
            <div class="vid">
                <video src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=DIrc4kocSbclKoD5ofR9v7Feb3EdEzGR"
                       autoplay playsinline loop muted class="timelapse"></video>
            </div>
            <h2>Constructed in Frost Library by Sonya Clark, Mika Obayashi, and Mike Chesworth, the monument was a
                physical art installation occupying the same space that student protestors did during Amherst Uprising,
                as they made their own calls for solidarity and justice.</h2>
            <div class="cols2">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=qCSNB30iPMbXKJA6hwacjdUE6zkQppR9"
                     alt="" class="colImg2">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=Vg4fcmwIE9WioOpxuRFQEqV9000xlRxl"
                     alt="" class="colImg2">
            </div>
            <div class="vid">
                <video src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=MxGTrwAGrH1orz95iTBTsBqYFNbbD9la"
                       autoplay playsinline loop muted class="timelapse"></video>
            </div>
            <h2>The Solidarity Monument held each of the sculpted books submitted to the project, becoming a Library
                Within A Library — collection of books selected and sculpted by an international community of people
                dedicated to solidarity with Black & Indigenous communities.</h2>
            <div class="cols3">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=4ov3xRlJyrHx44O8gjGiARKp9MjbBlqu"
                     alt="Sonya Clark walks within the Solidarity Monument" class="colImg3">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=AH5WeFqRTsd8BklCsTvhCIC7SdUC8Pkl"
                     alt="Sonya Clark stands outside of the Solidarity Monument" class="colImg3">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=pQqWclUWDQfUkV1CtIzGPg6tt8EuWxpi"
                     alt="An aerial view of the Solidarity Monument with students walking within" class="colImg3">
            </div>
            <div class="cols2">
                <h2>After the completion of the Monument, Amherst College students joined Sonya Clark to reflect on the
                    meaning of solidarity, the history of
                    Amherst Uprising, and the legacies of settler colonialism</h2>
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=qaPvERfh4fgy5ZlensxdMFnOFLhOBhLB"
                     alt="Students standing within the Solidarity Monument and reading reflections" class="colImg2">
            </div>
            <div class="cols2">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=NZJSYB1s78uXzlzkJ3e1ewaiUAXW1KMe"
                     alt="Students standing outside the Solidarity Monument and reading reflections" class="colImg2">
                <h2>Each student activated the space by choosing a reflection submitted to the project that
                    resonated with them and reading it aloud</h2>
            </div>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=bkldvEsAUaYf8vviDy6DLW1JewMwC55y"
                 alt="Students standing outside the Solidarity Monument and reading reflections"
                 class="colImg2 centBig">
            <div class="cols">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=j7kXmVnJQK346ZXqsQL7hRoh7lLCqchU"
                     alt="Sonya Clark and Mika Obayashi discuss the contruction of the Solidarity Monument"
                     class="colImg dip">
                <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=iEHljK9fkvqmMiN0zpVabKqTIApy04C2"
                     alt="Amir Denzel Hall seen through the gaps between the sculpted books of the Solidarity Monument"
                     class="colImg">
            </div>
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=bh6WqHXjWD9R0tqNb0UmYIRaIv4spfFX"
                 alt="Mika Obayashi, Jacinta Smith, Andrew W. Smith, Sonya Clark, and Amir Denzel Hall at the Solidarity Monument"
                 class="centImg">
            <h2>Members of the Solidarity Book Project team gathered in November 2021 for the closing events of the
                project. Following the closing of the Monument, the solidarity books were dispersed, some being sent back to their original creators and others being preserved at Amherst College. We dispersed the solidarity books back into the world to act as seeds that may grow into their own projects and libraries of solidarity.</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Writers",
    }
</script>

<style scoped lang="scss">
    #MonumentPhotos {
        flex-grow: 1;
        max-width: 100vw;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    .cols {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 50% 50%;
        background-color: #988FBB;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 100%;
        }
    }

    .colBorder {
        background-color: #988FBB;
    }

    .cols2 {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        max-width: 1600px;
        margin: 0 auto;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 100%;
        }
    }

    .cols3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 40px 120px;
        grid-gap: 80px;
        align-items: center;

        .colImg3 {
            width: 100%;
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
        }

        @media only screen and (max-width: 800px) {
            padding: 40px 60px;
            grid-gap: 40px;
            grid-template-columns: 100%;
        }
    }

    img {
        object-position: center;
        object-fit: cover;
    }

    .bannerImg {
        width: 100%;
        height: 70vh;
        max-height: 1000px;
    }

    .bannerImg2 {
        width: 100%;
        max-width: 1600px;
        max-height: 1000px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .colImg {
        width: 100%;
        height: 60vh;
        @media only screen and (max-width: 1600px) {
            height: 45vh;
        }
        @media only screen and (max-width: 1300px) {
            height: 35vh;
        }
        @media only screen and (max-width: 1100px) {
            height: 25vh;
        }
    }

    .colImg2 {
        width: 80%;
        margin: 40px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
    }

    h2 {
        text-align: center;
        max-width: 1400px;
        width: 80%;
        margin: 20px auto;
        font-weight: normal;
        font-size: 32px;
        line-height: 1.5;
    }

    .centBig {
        width: 80%;
        max-width: 1200px;
        display: flex;
        justify-content: center;
        margin: 40px auto 80px auto;
    }

    .centImg {
        width: 60%;
        max-width: 700px;
        display: flex;
        justify-content: center;
        margin: 60px auto 0 auto;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
    }

    .dip {
        @media only screen and (max-width: 800px) {
            display: none;
        }
    }

    .vid {
        display: flex;
        justify-content: center;
        padding: 18px 0;
    }

    .timelapse {
        margin: 0 auto;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);

    }
</style>