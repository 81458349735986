<template>
    <div class="transitionPage">
        <div class="section1">
            <div class="page">
                <div class="viewer">
                    <div class="content">
                        <div class="overlay">
                            <iframe class="reflectVid centerediFrame" width="890" height="501"
                                    :src="currentReflection.youtubeLink"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            <h2 class="pageSubTitle">{{ currentReflection.name }}</h2>
                            <p class="pageCaption">#SolidarityReflection</p>
                        </div>
                    </div>
                    <div class="bgText">
                        <div v-for="reflection in reflections" :key="reflection.ID" class="marquee">
                            <div :class="reflection.Move">
                                <p class="reflectionWritten">
                            <span v-for="indiv in reflection.ReflectionLines"
                                  class="reflectionIndiv" :key="indiv">{{ indiv }} </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gridSection">
                    <div class="gridSectionContent">
                        <h3 class="gridSectionTitle">Listen to another Reflection</h3>
                        <div class="vidGrid2">
                            <ReflectionBox v-for="reflection in result" :key="reflection.slug"
                                           :currentReflectionBox="reflection"></ReflectionBox>
                        </div>
                    </div>
                    <div class="paginationNav">
                        <p>Page {{ currentPage }} of {{ lastPage }}</p>
                        <div class="prevNext">
                            <p @click="prev">Previous Page</p>
                            <p @click="next">Next Page</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ReflectionBox from "../../components/archiveComponents/ReflectionBox";

    import {useArrayPagination} from "vue-composable";

    export default {
        name: 'Reflections',
        components: {ReflectionBox},
        data() {
            return {
                reflections: [],
                allReflections: [],
                theCurrentReflection: []
            }
        },
        computed: {
            currentReflection() {
                return this.theCurrentReflection
            }
        },
        watch: {
            '$route.params.participant_slug': function () {
                for (var i = 0; i < this.allReflections.length; i++) {
                    if (this.allReflections[i].slug === this.$route.params.participant_slug) {
                        this.theCurrentReflection = this.allReflections[i]
                    }
                }
            }
        },
        created() {
            const request2 = new XMLHttpRequest();
            request2.open("GET", "/reflections-Marquee.json", false);
            request2.send(null)
            this.reflections = (JSON.parse(request2.responseText));
        },
        mounted() {
            const request = new XMLHttpRequest();
            request.open("GET", "/reflections-Grid.json", false);
            request.send(null)
            this.allReflections = (JSON.parse(request.responseText));

            for (var i = 0; i < this.allReflections.length; i++) {
                if (this.allReflections[i].slug === this.$route.params.participant_slug) {
                    this.theCurrentReflection = this.allReflections[i]
                }
            }
        },
        setup() {
            const request = new XMLHttpRequest();
            request.open("GET", "/reflections-Grid.json", false);
            request.send(null)
            const array = (JSON.parse(request.responseText));

            function shuffle(array) {
                let currentIndex = array.length, randomIndex;
                while (currentIndex !== 0) {

                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;

                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
                return array;
            }

            shuffle(array)

            const {result, next, prev, currentPage, lastPage} = useArrayPagination(
                array,
                {
                    pageSize: 3
                }
            );
            return {result, next, prev, currentPage, lastPage};
        }
    }
</script>

<style scoped lang="scss">
    .viewer {
        display: block;
        height: 896px;
    }
    .content {
        height: 896px;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        display: flex;
        align-items: center;
    }

    .bgText {
        position: absolute;
        z-index: 0;
        width: 100vw;
        max-width: 100%;
        overflow-x: hidden;
    }

    .overlay {
        width: 70%;
        // height: 70%;
        max-width: 1140px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        z-index: 1;
        background-color: rgba(152, 143, 187, .8);
        color: white;
        padding-top: 74px;
        filter: drop-shadow(4px 8px 8px rgba(0, 0, 0, 0.4));
        @media only screen and (max-width: 1300px) {
            width: 80%;
            max-height: 80vh;
        }
        @media only screen and (max-width: 800px) {
            padding-top: 32px;
            width: 90%;
        }
    }

    .reflectionWritten {
        font-size: 32px;
        line-height: 2;
        width: 100%;
        font-family: 'freight-text-pro', serif;
        text-align: justify;
        //@media only screen and (max-width: 1200px) {
        //    font-size: 24px;
        //    line-height: 1;
        //}
    }

    .reflectionIndiv {
        cursor: default;
        transition: 1s;
        color: rgba(196, 196, 196, 0.65);

        &:hover {
            color: rgba(45, 48, 71, 1);
            cursor: default;
        }
    }

    p {
        margin: 0;
    }

    .marquee {
        position: relative;
        width: 100vw;
        max-width: 100%;
        height: 64px;
        overflow-x: hidden;
        //@media only screen and (max-width: 1200px) {
        //    height: 52px;
        //}
    }

    .track {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 90s linear infinite;
    }

    .track2 {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee2 150s linear infinite;
        marquee-direction: reverse;
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-50%);
        }
    }

    @keyframes marquee2 {
        from {
            transform: translateX(-50%);
        }
        to {
            transform: translateX(0%);
        }
    }

    .reflectVid {
        filter: drop-shadow(4px 8px 8px rgba(0, 0, 0, 0.4));
        max-width: 90%;
        height: 45vh;
        max-height: 900px;
        @media only screen and (max-width: 1200px) {
            max-height: 750px;
            height: 40vh;
        }
    }

    .pageTitle {
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        font-size: 90px;
        margin-bottom: -20px;;
        margin-top: 20px;
        letter-spacing: 4px;
    }

    .pageSubTitle {
        text-align: center;
        font-size: 36px;
        margin: 12px 0 0 0;
    }

    .pageCaption {
        text-align: center;
        margin-top: 0;
        padding-bottom: 12px;
    }

    .gridSection {
        border-top: 6px solid #6D648E;
        background-color: rgba(152, 143, 187, .4);
        padding-bottom: 40px;
    }

    .gridSectionTitle {
        color: #4c4c4c;
        margin: 0;
        padding: 16px 0;
        font-size: 30px;
        @media only screen and (max-width: 1100px) {
            text-align: center;
        }
    }

    .gridSectionContent {
        max-width: 1440px;
        width: 95%;
        margin: 0 auto;

        h2 {
            padding-top: 26px;
        }
    }

    .vidGrid2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
        align-content: center;
        justify-items: stretch;
        @media only screen and (max-width: 1100px) {
            grid-template-columns: 1fr;
            justify-items: center;
        }
    }

    .vidSelect {
        padding: 20px 0;
    }

    .vidSelectImg {
        width: 400px;
    }

    .paginationNav {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding-top: 20px;
        padding-right: 20px;
        max-width: 1400px;
        margin: 0 auto;

        p {
            margin: 0 12px;
        }

        @media only screen and (max-width: 1100px) {
            align-items: center;
        }
    }

    .prevNext {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        font-weight: bold;
    }
</style>