<template>
    <div class="transitionPage">
        <div id="Giving">
            <h2>Giving</h2>
            <h3>How We Give:</h3>
            <p>The Solidarity Book Project charts a new model of fundraising and gift giving to support decolonizing and
                redistributing wealth to marginalized communities. In our model, instead of individuals making gifts we
                called on people to participate through reflections, readings, and art making. Each of these actions are
                ways
                to raise awareness, celebrate Black & Indigenous authors, and create material objects that represent our
                commitment to solidarity.</p>
            <p>Each act of participation translated to funds through our partnership with Amherst College. Amherst
                pledged
                up to $100,00 in funding to support education, literacy, and book knowledge in Black & Indigenous
                communities.</p>
            <ul>
                <li>#SolidarityReflection submissions were matched by $25</li>
                <li>#SolidarityReading submissions were matched by $50</li>
                <li>#SolidaritySculpting submissions were matched by $200</li>
            </ul>
            <p>Giving in this way is an act of resistance against legacies of settler colonialism and anti-Black racism
                by
                directly supporting affected communities. Together, we reflect on our history, while committing to a
                more
                equitable future.
            </p>
            <h3>Recipient Organizations:</h3>
            <p>We partnered with Amherst College, who matched your participation and helped select these vital
                organizations that are working to support literacy, education, book knowledge, and cultural preservation
                in Black & Indigenous communities. A total of $100,000 was distributed across the recipient organizations. Learn more about each organization below:</p>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=AHu6FpmpueYhv5EoJi39JfriWajxY6eU"
                        alt="" class="orgLogo">
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=Imltekukh0dE8N44INo6Wn7Nnrv721LN"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>American Indian College Fund</h4>
                    <a href="https://collegefund.org/" class="orgLink" target="_blank">https://collegefund.org/</a>
                    <p>The American Indian College Fund invests in Native students and tribal college education to
                        transform lives and communities.</p>
                    <p>The American Indian College Fund was founded in 1989. For over 31 years, the College Fund has
                        been the nation’s largest charity supporting Native student access to higher education. They
                        provide scholarships and programming to improve Native American student access to higher
                        education,
                        and the support and tools for them to succeed once they are there.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=X7VDnBLHPcNj82rNvPPvDZgQyR6Pdgzr"
                        alt="" class="orgLogo">
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=WNI2NYMsOArzRtBW1QoXKRu8i6uvnc6z"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>Free Minds Book Club & Writing Workshop</h4>
                    <a href="https://freemindsbookclub.org/" class="orgLink" target="_blank">https://freemindsbookclub.org/</a>
                    <p>Free Minds Book Club & Writing Workshop uses the literary arts, workforce development, and
                        violence prevention to connect incarcerated and formerly incarcerated youths and adults to their
                        voices, their purpose, and the wider community.</p>
                    <p>Our vision is that every Free Minds member is able to access the necessary opportunities,
                        inspiration, and support to write new chapters in their lives.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <h3 style="color: #890408; font-size: 30px; margin: 0 0 8px 0; font-family: 'Georgia', serif; font-weight: normal;">
                        Gedakina, Inc.</h3>
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=7AP9N2qdkFmDDRucRe1XBuFQHBxQ81ng"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>Gedakina</h4>
                    <a href="http://gedakina.org/" class="orgLink" target="_blank">http://gedakina.org/
                    </a>
                    <p>Gedakina is a multigenerational endeavor to strengthen and revitalize the cultural knowledge and
                        identity of Native American women and their families from across New England. We work to
                        conserve our traditional homelands and places of historical, ecological, and spiritual
                        significance and to educate others as to their importance.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=lzp5PFGIE6RmgvbgxpLWPqkLYwuE2f9p"
                        alt="" class="orgLogo">
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=SNG5tFTxjjksOjvWmVZFmrZlYiPdxkI1"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>Indigo Arts Alliance</h4>
                    <a href="https://indigoartsalliance.me/" class="orgLink" target="_blank">https://indigoartsalliance.me/</a>
                    <p>Indigo Arts Alliance embodies a Black-led, multiracial approach to the rich intersections of
                        citizenship, community-building, and creativity. More than ever, our world needs people who can
                        help us imagine more compassionate, generous, and welcoming human societies. Indigo does that
                        work.</p>
                    <p></p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=n8C6HmD4np8shQs0RllaUsnnR5aBAllW"
                        alt="" class="orgLogo">
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=EIVOyjE0dJ30PEyUJ47fCyMi9oguwuxr"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>Literacy Lab</h4>
                    <a href="https://theliteracylab.org/" class="orgLink"
                       target="_blank">https://theliteracylab.org/</a>
                    <p>The Literacy Lab’s mission is to provide children from low-income families with individualized
                        reading instruction to improve their literacy skills, leading to greater success in school and
                        increased opportunities in life.</p>
                    <p>The Literacy Lab serves children from age three through grade three. We partner with school
                        districts to help close the literacy gap largely by embedding full-time, rigorously-trained
                        tutors in early childhood centers and elementary schools. The Literacy Lab also supports early
                        literacy for families experiencing homelessness.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=KOvV7I1lbLJMw0pPoc9Mbnv9vH34rVSK"
                        alt="" class="orgLogo">
<!--                    <img-->
<!--                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=7AP9N2qdkFmDDRucRe1XBuFQHBxQ81ng"-->
<!--                        alt="" class="orgImg">-->
                </div>
                <div>
                    <h4>Ohketeau Cultural Center</h4>
                    <a href="https://www.ohketeau.org/" class="orgLink" target="_blank">https://www.ohketeau.org/</a>
                    <p>Ohketeau is the Nipmuc word for a place to plant and grow. The Ohketeau Cultural Center serves as
                        a place of creation and growth; where ideas will be born, nurtured, and lived out. Not only does
                        this space allow for creative projects, but it is a safe haven for Indigenous community.</p>
                    <p>Ohketeau allows for the opportunity for interdisciplinary education through cultural workshops,
                        dance, music, art, indoor and outdoor, activities that allows participants the opportunity to
                        fully express their talents.</p>
                    <p>At Ohketeau we believe teaching and learning is a relationship of reciprocity. The interaction
                        with teacher and participant will be one that recognizes each others abilities while also
                        exploring new ways to express our culture.</p>
                    <p>In addition, our mission is to provide a safe, rewarding and enriching experience for the
                        Indigenous community of the region.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=BSMFob7ydMcQj91KXhYF1kN6D9QV5bjW"
                        alt="" class="orgLogo">
                </div>
                <div>
                    <h4>Tomaquag Museum</h4>
                    <a href="https://www.tomaquagmuseum.org/" class="orgLink" target="_blank">https://www.tomaquagmuseum.org/
                    </a>
                    <p>Our mission at Tomaquag Museum is to educate the public and promote thoughtful dialogue regarding
                        Indigenous history, culture, arts, and Mother Earth and connect to Native issues of today.
                        Tomaquag Museum envisions its future as an Indigenous Cultural Education destination that
                        engages visitors in thoughtful dialogue that promotes understanding and strives to create
                        experiences that transform people's lives by broadening their perspectives, attitudes, and
                        knowledge of Indigenous Cultures and the interrelationship with the wider world.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=dlSsvzIminX3dXLyJ81yCvPalQBREzCr"
                        alt="" class="orgLogo" style="background-color: #585858; padding: 4px 4px 0 4px;">
                </div>
                <div>
                    <h4>United Negro College Fund</h4>
                    <a href="https://uncf.org/" class="orgLink" target="_blank">https://uncf.org/</a>
                    <p>UNCF envisions a nation where all Americans have equal access to a college education that prepares them for rich intellectual lives, competitive and fulfilling careers, engaged citizenship and service to our nation.</p>
                    <p>UNCF’s mission is to build a robust and nationally-recognized pipeline of under-represented students who, because of UNCF support, become highly-qualified college graduates and to ensure that our network of member institutions is a respected model of best practice in moving students to and through college.</p>
                    <p>UNCF’s North Star is to increase the total annual number of African American college graduates by focusing on activities that ensure more students are college-ready, enroll in college and persist to graduation. This is done through a three-pillar strategy. Positioning member institutions as a viable college option for students and investing in institutional capacity to improve student outcomes; creating transformational support programs to ensure students are enrolling and persisting through college completion; building awareness of educational attainment and cultivating college-going behaviors within the African American community.</p>
                </div>
            </div>
            <hr>
            <div class="cols">
                <div class="imgCol">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=T7chrB9v3hk9k6E5mlIrd5z60oOJsQ9Z"
                        alt="" class="orgLogo">
                </div>
                <div>
                    <h4>Wôpanâak Language Reclamation Project</h4>
                    <a href="https://www.wlrp.org/" class="orgLink" target="_blank">https://www.wlrp.org/</a>
                    <p>We are ensuring the cultural survival of the Wampanoag Tribe through the reclamation of the tribe's indigenous language... a language nearly lost due to the cultural genocidal policies of European colonizers on this continent.  Our reclamation strategies include the establishing in 2016 of the Weetumuw School, our PreK-4th grade language school based in Montessori pedagogy; our afterschool language enrichment programs targeted to Elementary and Middle School students in the Mashpee Public School District; Levels 1 through 4 credit-bearing Wopanaak language classes in the Mashpee High School; online year-round community classes including classes especially for tribal elders.  In this way, we are ensuring that Wampanoag families have access to their language birthright.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Giving",
    }
</script>

<style scoped lang="scss">
    #Giving {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
        padding-bottom: 40px;
    }

    li {
        font-size: 24px;
    }

    h4 {
        margin: 4px 0;
    }

    a {
        font-size: 20px;
        font-variant: small-caps;
        color: #7f76a4;
        font-weight: bold;
    }

    .cols {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 20% 80%;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 1fr;

        }
    }

    .imgCol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 20px;
    }

    .orgLogo {
        width: 250px;
        max-width: 100%;
    }

    .orgImg {
        width: 225px;
    }

    hr {
        width: 80%;
        margin: 40px auto;
    }
</style>