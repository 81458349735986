<template>
    <div className="transitionPage">
        <div id="Acknowledgments" className="pageContainer">
            <h2>Acknowledgments</h2>
            <p>The Solidarity Book Project is grateful to Amherst College for being our fundraising partner and
                supporting the project in so many ways. Specifically, the Office of the President, the Office of
                Communications, the Mead Art Museum, the Bicentennial Committee, Frost Library Staff, the Mail Center,
                Alumni Relations, IT, the Department of Art and the History of Art, First Year Seminar and LEAP
                Organizers, and the Common. We give special thanks to Biddy Martin, Bett Schumacher, Catherine Epstein,
                Stephanie Ramirez, Sandy Genelius, Willa Jarnagin, Roberta Diehl, Katharine Whittemore, Su Auerbach,
                Danielle Amodeo, Rebecca Kennedy, Steven Heim and the staff of the Circulation Desk, Martin Garnar, Sara
                Smith, Blake Doherty, Mike Kelly and the staff of Archive and Special Collections, Jen Acker, Richard
                Squier, Trish Connelly, Jennifer Kendall, Carol Allman-Morton, Affair Talibi, John Kunhardt, Seth Koen,
                Amrita Basu, Rhonda Cobham-Sander, Timothy Van Compernolle, Andreas Zanker, Yael Rice, Marie Lalor,
                Raphaela Wade, Lisa Brooks, Alexis Scalise, Carly Maloy, Sage Innerarity, Aneeka Henderson, Melissa
                Makepeace-O’Neil, Niko Vicario, and so many more. We also extend our gratitude to the many students, alumni and
                faculty that not only participated but offered feedback and encouragement along the way.</p>
            <p>We are also grateful for the overwhelming support of our community beyond Amherst College. The Boston Athenaeum, the Fabric Workshop and Museum, the Indigo Arts Alliance, and the
                Immersive Realities Labs for the Humanities have offered their resources in service of this work.
                Special thanks to Christina Michelon, Marcia Minter, Ashley Page, and Jordia Benjamin from these
                organizations.
            </p>
            <p>We extend deep gratitude to Mike Chesworth, who supported the construction of the monument
                in Frost Library.</p>
            <p>We thank the writers who contributed to the project: Akwe Amosu, Onawumi Jean Moss, Celeste Mohammed, W.
                Ralph Eubanks, Madison Jamar, Shayla Lawson, Imani Perry, Lisa Brooks, Sara Smith, Rage Hezekiah, Amrita
                Basu, and Kiara Vigil.</p>
            <p>Special thanks and credit to the photographers and videographers at Amherst College whose work appears throughout the project and this website, specifically Marcus DeMaio, Maria Stenzel, and Jianing Li.</p>
            <p>And we are forever grateful to all of our participants, without whose submissions this project would
                not have existed. You answered the call to action. And because of your small acts of solidarity, we were
                able to raise $100,000 in support of Black and Indigenous communities.</p>
            <p><i>The Solidarity Book Project</i> is included in the traveling exhibit by Sonya Clark, <i>We Are Each Other</i>, co-organized by the High, the Cranbrook Art Museum in metro Detroit, and the Museum of Arts and Design in New York.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Acknowledgments",
    }
</script>

<style scoped lang="scss">
    #Acknowledgments {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        padding-bottom: 40px;
    }

</style>