<template>
    <div class="transitionPage">
        <div class="pageContainer">
            <div class="faq">
                <h2>FAQ</h2>
                <h3>General</h3>
                <div class="accordion">
                    <div class="accordion-item">
                        <button id="accordion-button-1" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">Why are you partnered with Amherst College?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>The Solidarity Book Project began in response to Amherst College’s request for Sonya Clark to
                                create an artwork commemorating the College’s Bicentennial.</p>
                            <p>The project commemorates not only Amherst College’s Bicentennial, but also 200 years of
                                Amherst College occupying land belonging Indigenous tribes, 50
                                years since the founding of the Black Studies Department at Amherst College, and 5 years
                                since the beginning of the Amherst Uprising student movement.</p>
                            <p>Sonya Clark called on Amherst College to acknowledge these histories and the ways in which
                                the legacies of settler colonialism and white supremacy extend far beyond the boundaries of
                                the College. Amherst took up the call to action and pledged $100,000 in gifts to nonprofits
                                supporting education and book knowledge in Black and Indigenous communities.</p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-2" aria-expanded="false" @click="toggleAccordion">
                        <span
                            class="accordion-title">Why is the project focused on Black & Indigenous communities?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>The Solidarity Book Project chose to focus on Black and Indigenous communities in
                                acknowledgement of the anniversaries of Bicentennial, the formation of the Black Studies
                                department at Amherst, and the beginning of Amherst Uprising. These anniversaries mark 200
                                years of Amherst’ occupation of Indigenous land and two landmark student activist movements
                                against anti-Black racism at Amherst College.</p>
                            <p>With these histories in mind, the project begins with a focus on Black and Indigenous
                                communities, while understanding that undoing settler colonialism and white supremacy
                                benefits all of our communities.</p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-3" aria-expanded="false" @click="toggleAccordion">
                        <span
                            class="accordion-title">How can I replicate something like this in my own community?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>We have been very intentional about documenting our process so that individuals and
                                organizations can replicate their own reverse fundraiser. Explore the project archive and visit <router-link :to="{ name: 'BringToYourInstitution'}"> Start Your Own Project</router-link> to
                                find out how.</p>
                        </div>
                    </div>
                </div>
                <h3>Participation</h3>
                <div class="accordion">
                    <div class="accordion-item">
                        <button id="accordion-button-4" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">Can I still make a book?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>Yes! While, we are no longer accepting sculpted books to be part of the Solidarity Monument at Frost
                                Library, we still encourage you to make a book and share it with us on social media! Learn
                                how to sculpt a book here:
                                <router-link :to="{ name: 'SculptingInstructions'}"> Sculpting Instructions</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-5" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">How long does it take to make a book?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>Most participants take 2-4 hours to sculpt a book.</p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-6" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">I would like a social media post that features me or my work to be taken down.</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>Please email us at solidaritybook [at] gmail [dot] com and let us know which post.</p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-7" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">When will my book be mailed back to me?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>If you selected to have your book mailed back to you in the submission form, we will send you
                                your book back in January 2022, after the closing of the exhibit.</p>
                        </div>
                    </div>
                </div>
                <h3>Giving</h3>
                <div class="accordion">
                    <div class="accordion-item">
                        <button id="accordion-button-8" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">How will the money raised be used?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>Every call to action was matched with a specific amount by Amherst College that would go
                                toward organizations supporting Black and Indigenous communities in the US.
                                #SolidarityReflection submissions were matched by $25. #SolidarityReading submissions were
                                matched by $50. #SolidaritySculpting submissions were matched by $200.</p>
                            <p>Learn more about our model for giving and the recipient organizations here: <router-link :to="{ name: 'Giving'}"> How We Give</router-link></p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-9" aria-expanded="false" @click="toggleAccordion">
                        <span
                            class="accordion-title">Where will Amherst College’s gifts go?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>With your help, we identified a list of potential recipients of the Amherst College gift.
                                These nonprofits submitted applications, which are currently being reviewed by the
                                Solidarity Book Project Team and by Amherst College. We will be announcing the recipients
                                shortly. Stay tuned!</p>
                        </div>
                    </div>
                </div>
                <h3>Exhibit</h3>
                <div class="accordion">
                    <div class="accordion-item">
                        <button id="accordion-button-10" aria-expanded="false" @click="toggleAccordion">
                            <span class="accordion-title">When is the exhibit?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>The exhibit was open from September to November 2021 in Robert Frost Library at Amherst
                                College.</p>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button id="accordion-button-11" aria-expanded="false" @click="toggleAccordion">
                        <span
                            class="accordion-title">How can I view the exhibit?</span>
                            <span class="icon" aria-hidden="true"></span>
                        </button>
                        <div class="accordion-content">
                            <p>Due to COVID-19 restrictions, only members of the Amherst College community (students,
                                faculty, staff) are allowed into buildings and to see the exhibit in person.</p>
                            <p>However, we are creating a 360 WebVR virtual exhibit so that you can still experience it
                                online. We’ll be announcing the launch of the virtual exhibit soon</p>
                            <p>You can also see photos from the exhibit here: <router-link :to="{ name: 'MonumentPhotos'}"> Photos of the Solidarity Monument</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FAQ',
        methods: {
            toggleAccordion(e) {
                const element = e.currentTarget
                const items = document.querySelectorAll(".accordion button");

                const itemToggle = element.getAttribute('aria-expanded');

                items.forEach(item => item.setAttribute('aria-expanded', 'false'))

                if (itemToggle === 'false') {
                    element.setAttribute('aria-expanded', 'true');
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .faq {
        width: 1400px;
        margin: 0 auto;
        max-width: 90%;
    }

    .pageContainer {
        padding-bottom: 40px;
    }

    h3 {
        font-family: 'freight-sans-pro', sans-serif;
        padding-top: 12px;
        margin-bottom: 12px;
    }

    p {
        line-height: 1.25;
    }

    $bg: #fff;
    $text: #5c5c5c;
    $gray: #4d5974;
    $lightgray: #bababa;
    $blue: #988FBB;

    .accordion {
        .accordion-item {
            border-bottom: 1px solid $lightgray;

            button[aria-expanded='true'] {
                border-bottom: 1px solid $blue;
            }
        }

        button {
            position: relative;
            display: block;
            text-align: left;
            width: 100%;
            padding: 1em 0;
            color: $text;
            font-weight: 400;
            border: none;
            background: none;
            outline: none;

            &:hover, &:focus {
                cursor: pointer;
                color: $blue;

                &::after {
                    cursor: pointer;
                    color: $blue;
                    border: 1px solid $blue;
                }
            }

            .accordion-title {
                font-family: 'freight-sans-pro', sans-serif;
                font-size: 24px;
                font-weight: bold;
                padding: 1em 1.5em 1em 0;
            }

            .icon {
                display: inline-block;
                position: absolute;
                top: 18px;
                right: 0;
                width: 22px;
                height: 22px;
                border: 1px solid;
                border-radius: 22px;
                @media only screen and (max-width: 700px) {
                    display: none;
                }
                &::before {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 9px;
                    left: 5px;
                    width: 10px;
                    height: 2px;
                    background: currentColor;
                }

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 5px;
                    left: 9px;
                    width: 2px;
                    height: 10px;
                    background: currentColor;
                }
            }
        }

        button[aria-expanded='true'] {
            color: $blue;

            .icon {
                &::after {
                    width: 0;
                }
            }

            + .accordion-content {
                opacity: 1;
                max-height: 350px;
                transition: all 400ms linear;
                will-change: opacity, max-height;
                @media only screen and (max-width: 800px) {
                    max-height: 950px;
                }
            }
        }

        .accordion-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 200ms linear, max-height 400ms linear;
            will-change: opacity, max-height;

            p {
                font-family: 'freight-text-pro', serif;
                font-size: 22px;
                font-weight: 300;
                margin: 20px 0;
                color: black;
            }
        }
    }
</style>