<template>
    <div class="transitionPage">
        <div class="section1">
            <h2>Solidarity Reflection</h2>
            <p>#SolidarityReflection was the first call to action of the project. We asked for participants to reflect on
                what solidarity means to them and to share moments when they have witnessed solidarity in their lives. Below is an
                archive of all of the video and audio responses to the call.</p>
            <p>Select a reflection to hear from participants in the project:</p>
            <div class="paginationNav">
                <p>Page {{ currentPage }} of {{ lastPage }}</p>
                <div class="prevNext">
                    <p @click="prev">Previous Page</p>
                    <p @click="next">Next Page</p>
                </div>
            </div>
            <div class="reflectionGrid">
                <ReflectionBox v-for="reflection in result" :key="reflection.slug"
                               :currentReflectionBox="reflection"></ReflectionBox>
            </div>
        </div>
    </div>
</template>

<script>
    import {useArrayPagination} from "vue-composable";
    import ReflectionBox from "../../components/archiveComponents/ReflectionBox";

    export default {
        name: "ReflectionGrid",
        components: {ReflectionBox},
        data() {
            return {
                reflectionGrid: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/reflections-Grid.json", false);
            request.send(null)
            this.reflectionGrid = (JSON.parse(request.responseText));
        },
        methods: {
            play(name) {
                document.getElementById(name).play();
            },
            pause(name) {
                document.getElementById(name).currentTime = 0;
                document.getElementById(name).pause();
            }
        },
        setup() {
            const request = new XMLHttpRequest();
            request.open("GET", "/reflections-Grid.json", false);
            request.send(null)
            const array = (JSON.parse(request.responseText));

            const {result, next, prev, currentPage, lastPage} = useArrayPagination(
                array,
                {
                    pageSize: 9
                }
            );
            return {result, next, prev, currentPage, lastPage};
        }
    }
</script>

<style scoped lang="scss">
    h3 {
        margin: 0;
        color: #4c4c4c;
    }

    a {
        text-decoration: none;
        display: inline-block;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 3px;
            display: block;
            margin-top: 5px;
            right: 0;
            background: #988FBB;
            transition: width .5s ease;
            -webkit-transition: width .5s ease;
        }

        &:hover:after {
            width: 100%;
            left: 0;
            background: #988FBB;
        }
    }

    .section1 {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
        flex-grow: 1;
    }

    .reflectionGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-gap: 20px;
        padding-bottom: 40px;
        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
        &::after {
            content: "";
            width : 408px;
            @media only screen and (max-width: 1300px) {
                width: 358px
            }
            @media only screen and (max-width: 800px) {
                width: 308px
            }
        }
    }

    .paginationNav {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding-bottom: 40px;
        // padding-right: 40px;
        p {
            margin: 0 12px;
            color: #6D648E;
        }
        @media only screen and (max-width: 800px) {
            align-items: center;
            text-align: center;
        }
    }

    .prevNext {
        display: flex;
        flex-direction: row;
        cursor: pointer;
    }
</style>