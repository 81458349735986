<template>
    <div class="transitionPage">
        <div id="Catalogue" class="pageContainer">
            <h2>Catalogue</h2>
            <p>The on-demand catalogue designed by Ziddi Msangi is available for purchase! This catalogue celebrates the Solidarity Book Project and all of its participants. A PDF version of the catalogue can be viewed here:
                <a href=""></a></p>
            <a href="https://www.blurb.com/books/11180921-solidarity-book-project" target="_blank"><p class="button">Click here to purchase the Solidarity Book Project Catalogue</p></a>
            <h3>Preview:</h3>
            <div class="center">
                <iframe allowfullscreen="" frameborder="0" height="800" id="bookshare-iframe" onload="this.focus()" scrolling="no" src="https://www.blurb.com/bookshare/app/index.html?bookId=11180921" width="900"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Catalogue"
    }
</script>

<style scoped lang="scss">
    #Catalogue {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
        flex-grow: 1;
    }
    
    .button {
        color: white;
        margin: 0 auto 20px auto;
        border-radius: 18px;
        background-color: #6D648E;
        &:hover {
            color: #FFFD82;
        }
    }
    
    .center {
        display: flex;
        justify-content: center;
    }

</style>