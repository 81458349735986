<template>
    <div class="totalPage">
        <Nav2/>
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </router-view>
        <Footer/>
    </div>
</template>

<script>
    import Nav2 from "./components/Nav2";
    import Footer from "./components/Footer";

    export default {
        components: {Footer, Nav2}
    }
</script>

<style lang="scss">
    @import url("https://use.typekit.net/kqq8spf.css");

    @font-face {
        font-family: freight-text-pro, serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: freight-sans-pro, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
    }

    #app {
        font-family: 'freight-text-pro', serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
    }

    .totalPage {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    .transitionPage {
        height: 100%;
        flex-grow: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .75s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transition: opacity .35s ease;
    }

    .pageContainer {
        height: 100%;
        flex-basis: 100%;
    }

    h1 {
        font-size: 52px;
    }

    h2 {
        font-size: 42px;
    }

    h3 {
        font-size: 28px;
    }

    h4 {
        font-size: 24px;
    }

    p {
        font-size: 24px;
        line-height: 1.25;
    }

    a {
        color: #6D648E;
        text-decoration: none;
        font-weight: bold;
    }

    .imgTreat1 {
        filter: drop-shadow(2px 8px 12px rgba(0, 0, 0, 0.35));
    }

    .centerediFrame {
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .yellowText {
        color: #FFFD82;
        font-weight: bold;
    }

    .button {
        text-align: center;
        font-weight: bold;
        background-color: #988FBB;
        width: 400px;
        display: flex;
        justify-content: center;
        padding: 16px 0;
    }

    .button:hover {
        cursor: pointer;
        color: #FFFD82
    }

    .quoteSplit {
        display: grid;
        grid-template-columns: 10% 90%;
    }

    .quotationMark {
        font-family: 'Times New Roman', serif;
        font-size: 72px;
    }

    .quoteText {
        font-style: italic;
        line-height: 1.5;
    }

    .quoteSign {
        text-align: right;
    }

    .quoteImage {
        padding-top: 24px;
    }

    .headerImg {
        width: 100%;
    }
</style>
