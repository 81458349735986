<template>
    <div class="transitionPage">
        <div id="sculpting">
            <div class="section1">
                <h2>Solidarity Sculpting</h2>
                <p>#SolidaritySculpting asked participants to sculpt books in solidarity with Black & Indigenous
                    communities. Through book sculpting, participants made their commitments to solidarity material and
                    created a lasting artwork that celebrates book knowledge. Below are many of the photos of participants with their sculpted books that were submitted to the project! </p>
                      <video class="video"
                             src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=mKjnz6TYBjQen7skZupxuJgIYKB9XwW8" autoplay
                             inline loop muted controls></video>
            </div>
            <div class="sculptingGrid">
                <img v-for="photo in sculptingPhotos" :key="photo.id" :src="photo.image" alt="Participants holding their sculpted solidarity books" class="sculptPhoto">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Sculpting',
        data() {
            return {
                sculptingPhotos: []
            }
        },
        created() {
            const request2 = new XMLHttpRequest();
            request2.open("GET", "/sculptingPhotos.json", false);
            request2.send(null)
            this.sculptingPhotos = (JSON.parse(request2.responseText));
    
            function shuffle(array) {
                let currentIndex = array.length, randomIndex;
                while (currentIndex !== 0) {
            
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
            
                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
                return array;
            }
    
            shuffle(this.sculptingPhotos)
        },
    }
</script>

<style scoped lang="scss">
    .section1 {
        max-width: 1400px;
        margin: 0 auto;
        padding-bottom: 40px;
        width: 90%;
    }

    .video {
        max-width: 100%;
        padding-bottom: 40px;
    }

    .sculptingGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #988FBB;
        width: 100%;
        padding: 20px 0;
    }

    .sculptPhoto {
        width: 400px;
        //margin: 15px;
        //box-shadow: 0 0 15px rgba(255, 255, 255, 0.25);
    }
</style>