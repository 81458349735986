<template>
    <div id="HomeLaunch" class="transitionPage">
        <div class="static">
            <h2 class="centerTitle">Solidarity is</h2>
        </div>
        <div class="wordRotate">
            <h3 class="wordMove slide">Empathy</h3>
            <h3 class="wordMove slide">Resistance</h3>
            <h3 class="wordMove slide">Unity</h3>
            <h3 class="wordMove slide">Power</h3>
            <h3 class="wordMove slide">Action</h3>
            <h3 class="wordMove slide">Liberation</h3>
            <h3 class="wordMove slide">Listening</h3>
            <h3 class="wordMove slide">Community</h3>
            <h3 class="wordMove slide">Care</h3>
        </div>
        <div class="pic-ctn">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=vpVABBNiMhEJhBSCIIeF0txJSziqucEY" alt="The materials needed to craft a solidarity book" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=LbSIZmiUHjgK6mnPR9zEIkumhq2besFb" alt="An audio visualization of a solidarity fist" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=hFwMuKEkXyOSZA0sfcl8EcgHxAOTBgPM" alt="Nancy Montgomery raises a fist alongside her sculpted solidarity books" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=328fjMngAsCsmqdH4P1aXksKLFZBCXrW" alt="Joyce J. Scott reflects on the meaning of solidarity" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=2eHxdDGSO1LwxxRRP2aEz8b9SxNelnMh" alt="A sculpted solidarity book" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=pBAIBaRhmgg7wDU5MWWM40At7kQjyE2B" alt="Sonya Clark walks within the Solidarity Monument" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=ftmML3WZ7B0x7V8FhNRXal86AKQGYLti" alt="Tim Bohn reads from Man Gone Down by Michael Thomas" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=yAqrulutxrw5KMEkZMAydPdHFtYpI5Zb" alt="Amherst College students craft solidarity books with Sonya Clark" class="pic slide">
            <img src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=6YvFdJtC9aBr3FrptQMYhC7BDUw0gOdr" alt="Jacinta Smith walks within the Solidarity Monument" class="pic slide">
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeLaunch",
        data() {
            return {}
        },
    }
</script>

<style scoped lang="scss">
    #HomeLaunch {
        max-width: 100vw;
        min-height: 80vh;
        height: 100%;
        background-color: #988FBB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        div {
            margin: -12px 0;
        }
    }

    .static {
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 84px;
            color: white;
            margin: 0;
            font-weight: normal;
            text-align: center;
            @media only screen and (max-width: 800px) {
                font-size: 72px;
            }
        }
    }
    .wordRotate {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        .wordMove {
            color: #FFFD82;
            text-align: center;
            margin: 0;
            font-size: 84px;
            font-weight: normal;
            font-family: 'freight-sans-pro', sans-serif;
            letter-spacing: 1px;
            @media only screen and (max-width: 800px) {
                font-size: 72px;
            }
        }
    }
    .wordMove {
        position: absolute;
        top: 0;
        //left: calc(50% - 100px);
        opacity: 0;
        animation: display 36s infinite;
    }

    .pic-ctn {
        position: relative;
        width: 100vw;
        height: 410px;
        padding-bottom: 20px;
    }

    .pic-ctn > img {
        margin-top: 50px;
        height: 350px;
        width: 350px;
        box-shadow: 0 0 24px rgba(0,0,0,0.4);
        // border-radius: 8px;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: calc(50% - 185px);
        opacity: 0;
        animation: display 36s infinite;
        @media only screen and (max-width: 700px) {
            height: 300px;
            width: 300px;
            left: calc(50% - 150px);
        }
    }

    .slide:nth-child(2) {
        animation-delay: 4s;
    }

    .slide:nth-child(3) {
        animation-delay: 8s;
    }

    .slide:nth-child(4) {
        animation-delay: 12s;
    }

    .slide:nth-child(5) {
        animation-delay: 16s;
    }

    .slide:nth-child(6) {
        animation-delay: 20s;
    }

    .slide:nth-child(7) {
        animation-delay: 24s;
    }

    .slide:nth-child(8) {
        animation-delay: 28s;
    }

    .slide:nth-child(9) {
        animation-delay: 32s;
    }

    @keyframes display {
        0% {
            transform: translateX(200px);
            opacity: 0;
        }
        5% {
            transform: translateX(0);
            opacity: 1;
        }
        10% {
            transform: translateX(0);
            opacity: 1;
        }
        15% {
            transform: translateX(-200px);
            opacity: 0;
        }
        100% {
            transform: translateX(-200px);
            opacity: 0;
        }
    }
</style>