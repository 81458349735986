<template>
    <div class="pageTransition">
        <div class="section1">
            <h2>Buying Books from Black & POC-Owned Bookstores</h2>
            <p class="description">This a list of Black and POC-Owned Bookstores collected by <a class="myLink" href="https://nonamebooks.com/Bookstores" target="_blank">No Name Book Club</a>! Please check out their program for the most updated list as well as to suggest additions. We have copied the list here as of 8/24/2021 just in case the original site becomes unavailable.</p>
            <p class="description">If you do not have access to a Black or POC-Owned bookstore in your region, we encourage you to support local independent and used bookstores as well. <a href="https://bookshop.org/shop/solidaritybook" target="_blank">Bookshop.org</a> is a great online option.</p>
            <div class="columns">
                <div class="col1">
                    <p class="sectionTitle">Online-Only</p>
                    <a href="https://rep.club/collections/noname" target="_blank">Reparations Club</a>
                    <a href="https://www.lovingmebooks.com/" target="_blank">Loving Me Books</a><a
                    href="https://www.kizzysbooksandmore.com/" target="_blank">Kizzy’s Books &amp; More</a>
                    <a href="http://www.blackclassicbooks.com/all-titles/" target="_blank">Black Classic Press</a>
                    <a href="https://keybookstore.com/shop/" target="_blank">Key Bookstore</a>
                    <a href="https://www.theliteracyshop.com/shop" target="_blank">The Literacy Shop</a>
                    <a href="https://44thand3rdbooksellers.indielite.org/" target="_blank">44th &amp; 3rd</a>
                    <a href="https://bookshop.org/lists/books-for-the-homies" target="_blank">The Lifted Libary</a>

                    <p class="sectionTitle">Alabama</p>
                    Montgomery
                    <a href="https://www.1977books.com/" target="_blank">1977 Books</a>

                    <p class="sectionTitle">Arizona</p>
                    Phoenix
                    <a target="_blank" href="https://grassrootz-books.myshopify.com/" rel="noreferrer nofollow noopener">Grassrootz
                        Bookstore</a>
                    <a href="https://palabrasbookstore.indiecommerce.com/welcome" target="_blank">Palabras Books</a>

                    <p class="sectionTitle">Arkansas</p>
                    Little Rock
                    <a href="https://www.pyramid1988.com/" target="_blank">Pyramid Art Books &amp; Custom Framing</a>&nbsp;
                    &nbsp;


                    <p class="sectionTitle">California</p>
                    LA
                    <a href="https://www.esowonbookstore.com/" target="_blank">Eso Won Bookstore</a>
                    <a href="https://malikbooks.com/shop" target="_blank">Malik Books</a>
                    <a href="https://rep.club/collections/books" target="_blank">Reparations Club</a>
                    Long Beach
                    <a href="https://shadesofafrika.com/books/" target="_blank">Shades of Afrika</a>
                    Monterey
                    <a href="https://oldcapitolbooks.com/books/" target="_blank">Old Capitol Books</a>
                    Oakland
                    <a href="https://twitter.com/marcusbooks/status/1261717916728377346" target="_blank">Marcus Books</a>
                    Sacramento
                    <a href="https://carolsbookstore.com/" target="_blank">Carol’s Books</a>
                    <a href="https://underground-books.indiecommerce.com/" target="_blank">Underground Books</a>
                    Vallejo
                    <a href="https://ashaybythebay.com/" target="_blank">Ashay by the Bay</a>


                    <p class="sectionTitle">Colorado</p>
                    Denver
                    <a href="https://www.shopatmatter.com/product-category/books/" target="_blank">Shop at Matter</a>

                    <p class="sectionTitle">Connecticut</p>Saybrook
                    <a href="https://burgundybooks.com/shop" target="_blank">Burgundy Books</a><a
                    href="https://www.peoplegetreadybooks.com/" target="_blank">People Get Ready</a>

                    <p class="sectionTitle">D.C.</p>
                    <a href="https://www.mahoganybooks.com/" target="_blank">Mahogany Books</a>
                    <a href="https://www.loyaltybookstores.com/" target="_blank">Loyalty Bookstore</a>
                    <a href="https://www.events.sankofa.com/shop" target="_blank">Sankofa Video Books &amp; Cafe</a>
                    <a href="https://bookshop.org/shop/solidstate" target="_blank">Solid State Books</a>

                    <p class="sectionTitle">Delaware</p>
                    Claymont
                    <a href="https://mejahbooks.com/" target="_blank">MeJah Books, Inc.</a>

                    <p class="sectionTitle">Florida</p>
                    Tampa
                    <a href="http://bradlcmuseum.com/" target="_blank">Best Richardson African Diaspora Literature &amp;
                        Culture Museum</a>
                    St. Petersburg
                    <a href="https://bookshop.org/shop/culturedbooks" target="_blank">Cultured Books</a>
                    Longwood
                    <a href="https://darebooks.com/" target="_blank">Dare Books</a>
                    Boynton Beach
                    <a href="https://pyramidbooks.indielite.org/" target="_blank">Pyramid Books</a>

                    <p class="sectionTitle">Georgia</p>
                    Atlanta
                    <a href="https://www.forkeepsbooks.com/collections/books" target="_blank">For Keeps Bookstore</a>
                    <a href="https://www.goodbooksatl.com/shop" target="_blank">Good Books</a>
                    <a href="https://www.medubookstore.com/cont.html" target="_blank">Medu Bookstore</a>
                    Decatur
                    <a href="https://www.braveandkindbooks.com/" target="_blank">Brave + Kind Bookshop</a>
                    <a href="https://ebaconline.indielite.org/" target="_blank">Enrichment </a>
                    <a href="https://listeningtreebooks.com/shop/" target="_blank">The Listening Tree</a>
                    Columbus
                    <a target="_blank" href="http://www.uniqueeuphonypublishing.com/" rel="noreferrer nofollow noopener">Unique
                        Euphony Publishing</a>
                    Covington
                    <a href="https://onyxbookstorecafe.com/collections/all" target="_blank">Onyx Bookstore Cafe</a>
                    Lithonia
                    <a href="https://www.blackdotccstore.com/" target="_blank">Black Dot Cultural Center &amp; Bookstore</a>
                    Mableton
                    <a href="https://bookshop.org/shop/AllThingsInspirationGift" target="_blank">All Things Inspiration
                        Giftique</a>
                    Memphis
                    <a target="_blank" href="http://theafricanplace.com/" rel="noreferrer nofollow noopener">The African
                        Place</a>
                    Morrow
                    <a href="https://www.facebook.com/Nubian-Bookstore-110668169013960/" target="_blank">NuBian Books</a>
                    Rincon
                    <a href="https://www.moorebooksllc.com/bookstore" target="_blank">Moore Books</a>

                    <p class="sectionTitle">Illinois</p>
                    Chicago
                    <a href="http://frontlinebookpublishing.com/shop/" target="_blank">Frontline Bookstore</a>
                    <a href="https://bookshop.org/shop/rosecafe" target="_blank">Rose Café</a>
                    <a href="http://www.semicolonchi.com/" target="_blank">Semicolon Bookstore &amp; Gallery</a>
                    <a href="https://www.facebook.com/UndergroundBookStore/?rf=849992281753499" target="_blank">The
                        Underground Bookstore</a>
                    Maywood
                    <a href="https://www.afriwarebooks.com/store/c1/Featured_Products.html" target="_blank">Afriware Books,
                        Co</a>

                    <p class="sectionTitle">Indiana</p>
                    Evansville
                    <a href="https://www.akomabooks.com/books" target="_blank">Akoma Novelties &amp; Books</a>
                    Kokomo
                    <a href="https://squareup.com/store/beyondbarcodesbookstore" target="_blank">Beyond Barcodes
                        Bookstore</a>
                    South Bend
                    <a href="https://www.brainlairbooks.com/" target="_blank">Brain Lair Books</a>

                    <p class="sectionTitle">Kentucky</p>
                    Lexington
                    <a href="https://www.wildfigbooksandcoffee.com/" target="_blank">Wild Fig Books &amp; Coffee</a>

                    <p class="sectionTitle">Louisiana</p>
                    Baton Rouge
                    <a target="_blank" href="https://www.facebook.com/btlbookstore1/" rel="noreferrer nofollow noopener">Between
                        the Lines Bookstore</a>
                    New Orleans
                    <a href="https://www.facebook.com/Community-Book-Center-178125373443/" target="_blank">Community Book
                        Center</a>

                </div>
                <div class="col2">
                    <p class="sectionTitle">Maryland</p>
                    Baltimore
                    <a href="https://www.facebook.com/everyonesplacebookstore/" target="_blank">Everyone’s Place</a>
                    Gwynn Oak
                    <a href="https://wisdombookcenter.com/" target="_blank">Wisdom Book Center</a>
                    Silver Spring
                    <a href="https://www.loyaltybookstores.com/" target="_blank">Loyalty Bookstore</a>

                    <p class="sectionTitle">Massachusetts</p>
                    Boston
                    <a href="https://frugalbookstore.net/" target="_blank">Frugal Book Store</a>
                    Rockport
                    <a href="https://susiesstories-107442.square.site/" target="_blank">Susie’s Stories</a>
                    Springfield
                    <a href="http://www.olivetreebooksonline.com/" target="_blank">Olive Tree Books N Voices</a>

                    <p class="sectionTitle">Michigan</p>
                    Ypsilanti
                    <a href="https://www.blackstonebookstore.com/browse/book" target="_blank">Black Stone Bookstore &amp;
                        Cultural Center</a>
                    Detroit
                    <a href="https://www.detroitbookcity.com/" target="_blank">Detroit Book City</a>
                    <a href="http://www.sourcebooksellers.com/" target="_blank">Source Booksellers</a>
                    Highland Park
                    <a href="http://site.nandisknowledgecafe.com/book-store/" target="_blank">Nandi’s Knowledge Cafe</a>
                    <a href="http://www.sourcebooksellers.com/" target="_blank"></a>

                    <p class="sectionTitle">Minnesota</p>
                    <a href="https://www.blackgarnetbooks.com" target="_blank">Black Garnet Books</a>

                    <p class="sectionTitle">Mississippi</p>
                    Jackson
                    <p class="phone">Marshall Books - <i>phone: 601-355-5335</i></p>
                    Laurel
                    <a target="_blank" href="https://aalbc.com/bookstores/store.php?store_name=Magnolia+Tree+Books"
                       rel="noreferrer nofollow noopener">Magnolia Tree Books</a>

                    <p class="sectionTitle">Missouri</p>
                    Kansas City
                    <a href="https://www.blissbooksandwine.com/online-new-books" target="_blank">Bliss Books &amp; Wine</a
                    ><a href="https://www.willasbookskc.com/books/" target="_blank">Willa’s Books and Vinyl</a>
                    University City
                    <a href="https://www.eyeseeme.com/" target="_blank">EyeSeeMe</a>

                    <p class="sectionTitle">Nebraska</p>
                    Omaha
                    <a href="http://aframericanbookstore.com/contact.shtml" target="_blank">Aframerican Bookstore</a>

                    <p class="sectionTitle">New Jersey</p>
                    Camden
                    <a href="http://www.launiquebooks.net/">La Unique African American Books &amp; Cultural Center</a>
                    Newark
                    <p class="phone">Source of Knowledge Book Store - <i>phone: 973-824-2556</i></p>

                    <p class="sectionTitle">New York</p>
                    Buffalo
                    <a href="https://www.facebook.com/pages/category/Independent-Bookstore/Zawadi-Books-466887943377904/"
                       target="_blank">Zawadi Books</a>
                    NYC
                    <a href="https://www.cafeconlibrosbk.com/shop" target="_blank">Cafe con Libros</a>
                    <a href="http://www.thelitbar.com/" target="_blank">The Lit Bar</a>
                    <a href="http://www.sistersuptownbookstore.com/" target="_blank">Sisters Uptown Bookstore</a>

                    <p class="sectionTitle">North Carolina</p>
                    Charlotte
                    <a href="https://shelvesbookstore.com/shop-books" target="_blank">Shelves</a>
                    Greensboro
                    <a target="_blank" href="https://bookshop.org/shop/boomerangbookshop"
                       rel="noreferrer nofollow noopener">Boomerang Book Shop</a>

                    <p class="sectionTitle">Ohio</p>
                    Akron
                    <a href="https://bookshop.org/shop/Elizabeths" target="_blank">Elizabeth’s Bookshop &amp; Writing
                        Centre</a>
                    Cincinnati
                    <a href="https://www.smithandhannonbookstore.com/" target="_blank">Smith Hannon Bookstore</a>
                    Cleveland
                    <a href="https://www.aculturalexchange.org/bookstore/" target="_blank">A Cultural Exchange</a>
                    <a href="https://bookshop.org/shop/Elizabeths" target="_blank"></a>
                    Columbus
                    <a href="https://www.facebook.com/UjamaaBookstore/?rf=1677381905876568" target="_blank">Alkebulan Ujaama
                        Book Store</a>

                    <p class="sectionTitle">Oklahoma</p>
                    Tulsa
                    <a href="https://www.readwithmochabooks.com/" target="_blank">Mocha Books</a>
                    <a href="https://www.fultonstreet918.com/" target="_blank">Fulton Street Books &amp; Coffee</a>

                    <p class="sectionTitle">Oregon</p>
                    Portland
                    <a href="https://thirdeyebag.com/" target="_blank">Third Eye Books</a>

                    <p class="sectionTitle">Pennsylvania</p>
                    Lansdale
                    <p class="phone">The Black Reserve Bookstore - <i>phone: 267-221-3090</i></p>
                    Philadelphia
                    <a href="https://www.booksandstuff.info/shop-3" target="_blank">Books &amp; Stuff</a>
                    <a href="https://www.harriettsbookshop.com/" target="_blank">Harriet’s Bookshop</a>
                    <a href="https://hakimsbookstore.com/" target="_blank">Hakim’s Bookstore</a>
                    <a href="https://bookshop.org/shop/unclebobbies" target="_blank">Uncle Bobbie’s Coffee &amp; Books</a>
                    Ross Township
                    <a href="https://bookshop.org/shop/tinybookspgh" target="_blank">The Tiny Bookstore</a>

                    <p class="sectionTitle">South Carolina</p>
                    Goose Creek
                    <a href="https://turningpagebookshop.com/" target="_blank">Turning Page Bookshop</a>

                    <p class="sectionTitle">Tennessee</p>
                    Nashville
                    <a target="_blank" href="https://www.facebook.com/AlkebulanImages/" rel="noreferrer nofollow noopener">Alkebu-Lan
                        Images Bookstore</a>

                    <p class="sectionTitle">Texas</p>
                    Austin
                    <a href="https://blackpearlbookstore.com/?q=h.tviewer&amp;filt[status]=+rankusranked&amp;using_sb=status"
                       target="_blank">Black Pearl Books</a>
                    Dallas
                    <a href="https://www.panafricanconnection.com/" target="_blank">Pan-African Connection</a>
                    Duncanville
                    <a href="https://endasbooktique.com/" target="_blank">Enda’s Booktique</a>
                    <a href="https://www.panafricanconnection.com/" target="_blank"></a>
                    Fort Worth
                    <a href="https://www.thedockbookshop.com/shop" target="_blank">The Dock Bookshop</a>
                    Houston
                    <a target="_blank" href="https://phil-paul-new-used-books.business.site/"
                       rel="noreferrer nofollow noopener">Phil and Paul’s Bookstore</a>
                    Killeen
                    <a href="https://blackworldbooks.org/" target="_blank">Black World Books</a>

                    <p class="sectionTitle">Virginia</p>
                    Alexandria
                    <a href="https://harambeebooks.org/" target="_blank">Harambee Books</a>
                    Chesapeake
                    <a href="https://squareup.com/store/urban-moon-books" target="_blank">Urban Moon Books</a>
                    Martinsville
                    <a href="https://www.booksandcranniesva.com/" target="_blank">Books And Crannies</a>
                    Norfolk
                    <a href="https://www.hocbookstore.com/" target="_blank">House Of Consciousness</a>
                    Virginia Beach
                    <a href="https://www.positivevibesva.com/" target="_blank">Positive Vibes</a>

                    <p class="sectionTitle">Washington</p>
                    Seattle
                    <a href="https://bookshop.org/lists/estelita-s-library" target="_blank">Estelita’s Library</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BuyBooks',
    }
</script>

<style scoped lang="scss">
    .section1 {
        max-width: 1400px;
        width: 90%;
        margin: 0 auto;
    }

    .myLink {
        color: #29A092;
        text-decoration: none;
        font-weight: bold;
    }

    .columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 40px;
        @media only screen and (max-width: 700px) {
            grid-template-columns: 1fr;

        }
    }

    .sectionTitle {
        font-weight: bold;
        margin-bottom: 8px;
    }

    .columns {
        font-family: freight-sans-pro, sans-serif;
        font-size: 18px;
        a, .phone {
            display: block;
            margin-inline-start: 40px;
            font-family: freight-sans-pro, sans-serif;
            font-size: 18px;
        }
    }

</style>