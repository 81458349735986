<template>
    <div class="reflectionBox" @mouseover="play(currentReflectionBox.slug)"
         @mouseleave="pause(currentReflectionBox.slug)">
        <router-link :to="{ name: 'ReflectionIndividual', params: { participant_slug: currentReflectionBox.slug } }">
            <video :src="currentReflectionBox.vid" :id="currentReflectionBox.slug" class="vidSize" muted
                   preload="auto"
                   :poster="currentReflectionBox.front" playsinline></video>
            <h3 class="reflectionName">{{ currentReflectionBox.name }}</h3>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "ReflectionBox",
        props: ['currentReflectionBox'],
        methods: {
            play(name) {
                document.getElementById(name).play();
            },
            pause(name) {
                document.getElementById(name).currentTime = 0;
                document.getElementById(name).pause();
            }
        }
    }
</script>

<style scoped lang="scss">
    h3 {
        margin: 0;
        color: #4c4c4c;
    }

    a {
        text-decoration: none;
        display: inline-block;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 3px;
            display: block;
            margin-top: 5px;
            right: 0;
            background: #988FBB;
            transition: width .5s ease;
            -webkit-transition: width .5s ease;
        }

        &:hover:after {
            width: 100%;
            left: 0;
            background: #988FBB;
        }
    }

    .reflectionBox {
        cursor: pointer;
    }

    .reflectionFont {
        width: 400px;
        object-fit: cover;
        @media only screen and (max-width: 1500px) {
            width: 375px
        }
        @media only screen and (max-width: 1350px) {
            width: 350px
        }
        @media only screen and (max-width: 1300px) {
            width: 325px
        }
        @media only screen and (max-width: 1200px) {
            width: 300px
        }
        @media only screen and (max-width: 1100px) {
            width: 400px
        }
        @media only screen and (max-width: 950px) {
            width: 350px
        }
        @media only screen and (max-width: 850px) {
            width: 325px
        }
    }

    .reflectionName {
        text-align: center;
        margin-top: 6px;
    }

    .vidSize {
        width: 400px;
        overflow: hidden;
        border: 4px solid #6D648E;
        @media only screen and (max-width: 1500px) {
            width: 375px
        }
        @media only screen and (max-width: 1350px) {
            width: 350px
        }
        @media only screen and (max-width: 1300px) {
            width: 325px
        }
        @media only screen and (max-width: 1200px) {
            width: 300px
        }
        @media only screen and (max-width: 1100px) {
            width: 400px
        }
        @media only screen and (max-width: 950px) {
            width: 350px
        }
        @media only screen and (max-width: 850px) {
            width: 325px
        }
    }
</style>