<template>
    <div id="Nav">
        <div id="titleArea" class="titleContainer">
            <div class="titleContent">
                <h1 class="title">Solidarity Book Project</h1>
                <h2 id="stitle" class="subtitle">Art and activism in solidarity with Black & Indigenous communities</h2>
            </div>
            <div class="navContainer">
                <div class="navLinks">
                    <router-link :to="{ name: 'Home'}">
                        <h3>Home</h3>
                    </router-link>
                    <div class="dropdownLink">
                        <div class="dropdownText">
                            <h3>About</h3><i class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItems">
                            <router-link :to="{ name: 'About'}">
                                <h3>Overview</h3>
                            </router-link>
                            <router-link :to="{ name: 'FAQ'}">
                                <h3>FAQ</h3>
                            </router-link>
                            <router-link :to="{ name: 'Giving'}">
                                <h3>Giving</h3>
                            </router-link>
                            <router-link :to="{ name: 'Team'}">
                                <h3>Team</h3>
                            </router-link>
                            <router-link :to="{ name: 'Acknowledgements'}">
                                <h3>Acknowledgements</h3>
                            </router-link>
                        </div>
                    </div>
                    <router-link :to="{ name: 'VirtualMonument'}">
                        <h3>Virtual Monument</h3>
                    </router-link>
                    <div class="dropdownLink">
                        <div class="dropdownText">
                            <h3>Project Archive</h3><i class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItems">
                            <router-link :to="{ name: 'Reflections'}">
                                <h3>#SolidarityReflection</h3>
                            </router-link>
                            <router-link :to="{ name: 'Readings'}">
                                <h3>#SolidarityReading</h3>
                            </router-link>
                            <router-link :to="{ name: 'Sculpting'}">
                                <h3>#SolidaritySculpting</h3>
                            </router-link>
                            <router-link :to="{ name: 'Writers'}">
                                <h3>15 Writers on Solidarity</h3>
                            </router-link>
                            <router-link :to="{ name: 'MonumentPhotos'}">
                                <h3>Solidarity Monument Photos</h3>
                            </router-link>
                        </div>
                    </div>
                    <div class="dropdownLink">
                        <div class="dropdownText">
                            <h3>Resources</h3><i class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItems">
                            <router-link :to="{ name: 'SculptingInstructions'}">
                                <h3>Sculpting Instructions</h3>
                            </router-link>
                            <router-link :to="{ name: 'BringToYourInstitution'}">
                                <h3>Start Your Own Project</h3>
                            </router-link>
                            <router-link :to="{ name: 'BuyBooks'}">
                                <h3>Buy Books</h3>
                            </router-link>
                        </div>
                    </div>
                    <router-link :to="{ name: 'Press'}">
                        <h3>Press</h3>
                    </router-link>
                    <router-link :to="{ name: 'Catalogue'}">
                        <h3>Catalogue</h3>
                    </router-link>
                </div>
            </div>
            <div class="navContainerMobile">
                <div class="mobileMenuIcon" @click="mobileToggle">
                    <div id="hamburgerIcon" class="container" onclick="this.classList.toggle('active')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 200 200">
                            <g stroke-width="6.5" stroke-linecap="round">
                                <path
                                    d="M72 82.286h28.75"
                                    fill="#009100"
                                    fill-rule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                                    fill="none"
                                    stroke="#fff"
                                />
                                <path
                                    d="M72 125.143h28.75"
                                    fill="#009100"
                                    fill-rule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                                    fill="none"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 82.286h28.75"
                                    fill="#009100"
                                    fill-rule="evenodd"
                                    stroke="#fff"
                                />
                                <path
                                    d="M100.75 125.143h28.75"
                                    fill="#009100"
                                    fill-rule="evenodd"
                                    stroke="#fff"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div id="mobileDisplay" class="navLinksMobile">
                    <router-link :to="{ name: 'Home'}">
                        <h3 @click="closeMenu">Home</h3>
                    </router-link>
                    <div class="dropdownLinkMobile">
                        <div class="dropdownTextMobile">
                            <h3 @click="closeMenu">About</h3><i class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItemsMobile">
                            <router-link :to="{ name: 'About'}">
                                <h3 @click="closeMenu">Overview</h3>
                            </router-link>
                            <router-link :to="{ name: 'FAQ'}">
                                <h3 @click="closeMenu">FAQ</h3>
                            </router-link>
                            <router-link :to="{ name: 'Giving'}">
                                <h3 @click="closeMenu">Giving</h3>
                            </router-link>
                            <router-link :to="{ name: 'Team'}">
                                <h3 @click="closeMenu">Team</h3>
                            </router-link>
                        </div>
                    </div>
                    <router-link :to="{ name: 'VirtualMonument'}">
                        <h3 @click="closeMenu">Virtual Monument</h3>
                    </router-link>
                    <div class="dropdownLinkMobile">
                        <div class="dropdownTextMobile">
                            <h3 @click="closeMenu">Project Archive</h3><i
                            class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItemsMobile">
                            <router-link :to="{ name: 'Reflections'}">
                                <h3 @click="closeMenu">#SolidarityReflection</h3>
                            </router-link>
                            <router-link :to="{ name: 'Readings'}">
                                <h3 @click="closeMenu">#SolidarityReading</h3>
                            </router-link>
                            <router-link :to="{ name: 'Sculpting'}">
                                <h3 @click="closeMenu">#SolidaritySculpting</h3>
                            </router-link>
                            <router-link :to="{ name: 'Writers'}">
                                <h3 @click="closeMenu">15 Writers on Solidarity</h3>
                            </router-link>
                            <router-link :to="{ name: 'MonumentPhotos'}">
                                <h3 @click="closeMenu">Solidarity Monument Photos</h3>
                            </router-link>
                        </div>
                    </div>
                    <div class="dropdownLinkMobile">
                        <div class="dropdownTextMobile">
                            <h3 @click="closeMenu">Resources</h3><i class="large material-icons">arrow_drop_down</i>
                        </div>
                        <div class="dropdownItemsMobile">
                            <router-link :to="{ name: 'SculptingInstructions'}">
                                <h3 @click="closeMenu">Sculpting Instructions</h3>
                            </router-link>
                            <router-link :to="{ name: 'BringToYourInstitution'}">
                                <h3 @click="closeMenu">Start Your Own Project</h3>
                            </router-link>
                            <router-link :to="{ name: 'BuyBooks'}">
                                <h3 @click="closeMenu">Buy Books</h3>
                            </router-link>
                        </div>
                    </div>
                    <router-link :to="{ name: 'Press'}">
                        <h3 @click="closeMenu">Press</h3>
                    </router-link>
                    <router-link :to="{ name: 'Catalogue'}">
                        <h3 @click="closeMenu">Catalogue</h3>
                    </router-link>
                </div>
            </div>
        </div>
        <div id="topBtn" @click="backToTop">
            <i class="material-icons">keyboard_arrow_up</i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Nav2",
        watch: {
            $route() {
                const toTopBtn = document.getElementById('topBtn')
                const titleContainer = document.getElementById('titleArea')
                const stitle = document.getElementById('stitle')

                if (this.$route.fullPath === '/') {
                    toTopBtn.style.display = "none";
                    titleContainer.style.backgroundColor = "#6D648E";
                    titleContainer.style.boxShadow = "0 0 25px rgba(0,0,0,0.35)";
                    stitle.style.color = "#FFFD82";

                } else {
                    toTopBtn.style.display = "block";
                    titleContainer.style.backgroundColor = "#988FBB";
                    titleContainer.style.boxShadow = "0 0 25px rgba(0,0,0,0)";
                    stitle.style.color = "#fff";

                }
            }
        },
        methods: {
            mobileToggle() {
                const x = document.getElementById('mobileDisplay');
                if (x.style.display === "flex") {
                    x.style.display = "none";
                } else {
                    x.style.display = "flex";
                }
            },
            closeMenu() {
                const x = document.getElementById('hamburgerIcon');
                x.classList.toggle('active')

                this.mobileToggle()
            },
            backToTop() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
    }
</script>

<style scoped lang="scss">
    #Nav {
        z-index: 5;
    }

    #topBtn {
        position: fixed;
        bottom: 75px;
        right: 75px;
        background-color: rgba(152, 143, 187, 0.8);
        color: white;
        padding: 6px 10px;
        border-radius: 12px;
        cursor: pointer;
        z-index: 4;

        i {
            font-size: 36px;
        }

        h3 {
            margin: 0;
        }

        &:hover {
            background-color: #29a092;
            @media only screen and (max-width: 700px) {
                background-color: rgba(152, 143, 187, 0.8);
            }
        }

        @media only screen and (max-width: 700px) {
            bottom: 35px;
            right: 35px;
        }
    }

    .titleContainer {
        background-color: #988FBB;

        .titleContent {
            width: 90%;
            max-width: 1400px;
            margin: 0 auto;
            padding: 24px 0;

            .title {
                text-align: center;
                font-size: 52px;
                margin: 0;
                color: white;
                letter-spacing: 1px;
                @media only screen and (max-width: 350px) {
                    font-size: 48px;
                }
            }

            .subtitle {
                font-family: freight-sans-pro, sans-serif;
                text-align: center;
                font-size: 22px;
                margin: 0;
                color: #FFFD82;
                font-weight: normal;
                letter-spacing: 1px;
            }

            .logo {
                width: 80px;
            }

            .sm {
                display: flex;
                justify-content: center;
                grid-gap: 24px;

                .socialMediaIcon {
                    width: 24px;
                }
            }
        }
    }


    .navContainer {
        background-color: #6D648E;

        .navLinks {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            grid-gap: 40px;
            width: 90%;
            max-width: 1400px;
            margin: 0 auto;
            color: white;
            padding: 16px 0;
        }

        h3 {
            font-weight: bold;
            cursor: pointer;
            margin: 0;
            font-size: 24px;
        }

        @media only screen and (max-width: 800px) {
            display: none;
        }
    }

    .navContainerMobile {
        background-color: #6D648E;
        display: none;

        .mobileMenuIcon {
            color: white;
            font-size: 24px;
            text-align: center;
        }

        .navLinksMobile {
            display: none;
            flex-direction: column;
            grid-gap: 20px;
            width: 100%;
            margin: 0 auto;
            color: white;
            padding-bottom: 20px;
        }

        h3 {
            font-weight: bold;
            cursor: pointer;
            margin: 0;
            font-size: 24px;
            padding: 0 0 0 40px;
        }

        @media only screen and (max-width: 800px) {
            display: block;
        }
    }

    a {
        text-decoration: none;
        color: white;
    }

    a:hover {
        color: #FFFD82;
    }


    .dropdownLink {
        position: relative;
        display: inline-block;
    }

    .dropdownItems {
        display: none;
        position: absolute;
        background-color: #786E9C;
        min-width: 140px;
        box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
        padding: 12px 16px;
        z-index: 3;
        color: white;

        h3 {
            font-size: 24px;
            padding-left: 0;
            display: block;
            padding-bottom: 20px;
        }
    }

    .dropdownLink:hover .dropdownItems {
        display: block;
    }

    .dropdownText {
        display: flex;
        align-items: center;
    }


    .dropdownLinkMobile {
        position: relative;
        display: inline-block;
    }

    .dropdownItemsMobile {
        padding: 10px 0 0 50px;
        z-index: 3;
        color: white;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;

        h3 {
            font-size: 24px;
            display: block;
        }
    }

    .dropdownTextMobile {
        display: flex;
        align-items: center;
    }

    .container {
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    svg {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .active svg {
        transform: rotate(90deg);
    }

    path {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    path:nth-child(1) {
        transform-origin: 36% 40%;
    }

    path:nth-child(2) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(3) {
        transform-origin: 35% 63%;
    }

    path:nth-child(4) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(5) {
        transform-origin: 61% 52%;
    }

    path:nth-child(6) {
        transform-origin: 62% 52%;
    }

    .active path:nth-child(1) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }

    .active path:nth-child(2) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(3) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(4) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    .active path:nth-child(5) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    .active path:nth-child(6) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }

</style>