<template>
    <div id="footer">
        <div class="footerContent">
            <div class="container">
                <div class="img">
                    <img
                        src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=yJtTQfM4pPMQvPjTobeF3Kz1c9Xc11Yj"
                        width="150px"
                        alt="Amherst College Bicentennial Logo"
                        class="logo">
                </div>
                <div class="sm">
                    <a href="https://www.instagram.com/solidaritybook_/" target="_blank">
                        <img class="socialMediaIcon"
                             src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=5PqvHbRBcgfmqy4K1mf8Mxs24q2bPXPz"
                             alt="Instagram Icon">
                    </a>
                    <a href="https://twitter.com/SolidarityBook" target="_blank">
                        <img class="socialMediaIcon"
                             src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=XEFZpwWjsxZ158gj4eMLivosbY4qa0LX"
                             alt="Twitter Icon">
                    </a>
                    <a href="https://www.facebook.com/SolidarityBookProject" target="_blank">
                        <img
                            src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=iuRZvZzUABCBLzmzuBC9jF6wi4w1F8dd"
                            alt="Facebook Icon" class="socialMediaIcon">
                    </a>
                    <a href="https://www.youtube.com/channel/UCgCR08Mr42wl3DHnarBhAXw" target="_blank">
                        <img
                            src="https://solidaritybookproject.com/solidaritylibrary/wl/?id=fA1JVOPMasYcEf5KGreDmn97HWZls5ca"
                            alt="YouTube Icon" class="socialMediaIcon">
                    </a>
                </div>
                <div class="text2">
                    <p class="footerText">Site Design by <a href="https://awwsmith.com" target="_blank"
                                                            class="footLink">Andrew W. Smith</a></p>
                    <p class="footerText">Font Design by <a href="https://fonts.adobe.com/designers/joshua-darden"
                                                            target="_blank" class="footLink">Joshua Darden</a></p>
                    <p class="footerText">Hosted by the <a href="https://irlhumanities.org" target="_blank"
                                                           class="footLink">Immersive Realities Labs for the Humanities</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        watch: {
            $route() {
                const footerContainer = document.getElementById('footer')

                if (this.$route.fullPath === '/') {
                    footerContainer.style.boxShadow = "0 0 25px rgba(0,0,0,0.35)";
                } else {
                    footerContainer.style.boxShadow = "0 0 25px rgba(0,0,0,0)";
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    #footer {
        background-color: #6D648E;
        color: white;
        height: 250px;
        width: 100%;
        @media only screen and (max-width: 800px) {
            height: 100%;
        }
    }

    .footLink {
        text-decoration: none;
        color: #FFFD82;
    }

    .footerContent {
        max-width: 1400px;
        margin: 0 auto;
        padding: 32px 20px 32px 20px;
    }

    .footerMenuItem {
        font-size: 20px;
    }

    .footerText {
        font-size: 20px;
        text-align: right;
        @media only screen and (max-width: 800px) {
            text-align: center;
        }
    }

    .container {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 40px;
        align-items: center;
        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
    }

    .sm {
        display: flex;
        justify-content: center;
        grid-gap: 32px;
    }

    .img {
        justify-items: center;
    }

    .logo {
        margin: 0 auto;
    }

    .socialMediaIcon {
        width: 52px;
        border: 4px solid rgba(255, 253, 130, 0);

        &:hover {
            border-radius: 50%;
            border: 4px solid  #988FBB;
        }
    }
</style>